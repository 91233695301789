import { APTOS_NODE_API_KEY, REST_URL } from '@/constants';
import { AptosClient } from '@/libs/aptos';
import { useStore } from '@/store';
import { AptosClient as SdkClient, ClientConfig } from 'aptos';
import { watch } from 'vue';

const client = new AptosClient();
let sdkClient: SdkClient;
const setSdkClient = (address: string) => {
  const config: ClientConfig = {
    TOKEN: APTOS_NODE_API_KEY,
    WITH_CREDENTIALS: true,
    HEADERS: {
      API_KEY: APTOS_NODE_API_KEY,
    },
  };
  sdkClient = new SdkClient(address, config);
};

export function useAptosClient() {
  const store = useStore();
  setSdkClient(store.network.value?.restUrl || REST_URL);

  watch(
    store.network,
    (newValue, oldValue) => {
      if (
        newValue?.restUrl &&
        oldValue?.restUrl &&
        newValue?.restUrl !== oldValue?.restUrl
      ) {
        changeConfig(newValue.restUrl);
      }
    },
    { immediate: true, deep: true },
  );

  function changeConfig(baseUrl: string) {
    client.changeConfig(baseUrl);
    setSdkClient(baseUrl);
  }

  return {
    changeConfig,
    client,
    AptosSDK: sdkClient,
  };
}
