import { RouteRecordName } from 'vue-router';

export const APP_VERSION = import.meta.env.VITE_VERSION || 'stable';
export const BASE_URL = 'https://pontem.network/';

//mainnet
export const APTOS = '0x1::aptos_coin::AptosCoin';
export const NATIVE_COIN = '0x1::native_coin::NativeCoin';
export const DOODOO =
  '0x73eb84966be67e4697fc5ae75173ca6c35089e802650f75422ab49a8729704ec::coin::DooDoo';
export const DOODOO_DECIMALS = 8;
export const RETURD =
  '0xdf3d5eb83df80dfde8ceb1edaa24d8dbc46da6a89ae134a858338e1b86a29e38::coin::Returd';
export const RETURD_DECIMALS = 8;
export const SHRIMP =
  '0x55987edfab9a57f69bac759674f139ae473b5e09a9283848c1f87faf6fc1e789::shrimp::ShrimpCoin';
export const SHRIMP_DECIMALS = 2;
export const WETH =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH';
export const LZ_USDC =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC';
export const WH_USDC =
  '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T';
export const WH_USDT =
  '0xa2eda21a58856fda86451436513b867c97eecb4ba099da5775520e0f7492e852::coin::T';
export const UPTOS =
  '0x4fbed3f8a3fd8a11081c8b6392152a8b0cb14d70d0414586f0c9b858fcd2d6a7::UPTOS::UPTOS';
export const UPTOS_DECIMALS = 8;

//testnet
export const DGEN =
  '0x2ec4190dd6eec80913e02da22de89700a9b5e13e27b51750191b7ceb3eee1a2f::dgen::DGEN';

export const ONE_DAY_MS = 86400000;
export const YEAR_SECONDS = 365 * 24 * 60 * 60;
export const WEEK_SEC = 7 * 24 * 60 * 60;
const WEEK_MS = WEEK_SEC * 1000;
export const DENOMINATOR = 10000;
export const LP_DECIMALS = 6;
export const DATE_FORMAT = 'MMM D, YYYY';
export const DATE_LOCALE = { locales: 'en-US' };

export const RECALCULATION_TX_TIME = 1000;
export const RECALCULATION_TIME = 2000; // 2sec
export const RECALCULATION_BALANCE_TIME = 5000;
export const LOADING_ANIMATION_TIME = 3000;
export const ANIMATION_PAUSE_TIME = 3000;
export const NOTIFICATION_DURATION = 1000;
export const DEFAULT_STAKING_DURATION_MS = WEEK_MS;
export const SET_TIMEOUT_THRESHOLD = 2_147_483_647;

export const SHORT_LIQUIDSWAP_DEMO_TAG = 'devops.mom';

export const REST_URL =
  import.meta.env.VITE_REST_API_URL || 'https://api.mainnet.aptoslabs.com/v1';
export const API_URL =
  import.meta.env.VITE_API_URL || 'https://control.pontem.network/api';
export const APTOS_STAKING_API_URL =
  import.meta.env.VITE_APTOS_STAKING_API_URL ||
  'https://staking-testnet.devops.mom';

export const AIRDROP_API =
  import.meta.env.VITE_AIRDROP_API || 'https://api.airdrop.liquidswap.com';

export const AIRDROP_FE =
  import.meta.env.VITE_AIRDROP_FE || 'https://airdrop.liquidswap.com';

export const IPFS_HTTP = 'https://cloudflare-ipfs.com/ipfs/';

export const CACHE_NAME = 'pontem';

export const GAS_UNIT_PRICE_MIN = 100;
export const GAS_UNIT_PRICE_MAX = 2000;
export const DEFAULT_GAS_UNIT_PRICE = 100;
export const GAS_MIN = 500;
export const GAS_MAX = 300000;
export const DEFAULT_GAS = 9500;
export const DEFAULT_GAS_STABLE = 299500;

export const TRANSAK_API_KEY = import.meta.env.VITE_TRANSAK_API_KEY || '';

export const SCRIPTS_V1 = 'scripts';
export const SCRIPTS_V2 = 'scripts_v2';
export const VERSION_0 = 0;
export const VERSION_0_5 = 0.5;
export const VERSION_1 = 1;
export const VALID_VERSIONS = [VERSION_0, VERSION_0_5, VERSION_1] as const;

export const MULTI_ROUTER_DIRECT =
  import.meta.env.VITE_MULTI_ROUTER_DIRECT ||
  '0x99465840349525b3d648e609eacb056d5b61e3915e8a3972b431afc26e3f05a1';

export const MULTI_ROUTER_DEFAULT =
  import.meta.env.VITE_MULTI_ROUTER_DEFAULT ||
  '0x80273859084bc47f92a6c2d3e9257ebb2349668a1b0fb3db1d759a04c7628855';

export const MODULES_ACCOUNT =
  import.meta.env.VITE_MODULES_ACCOUNT ||
  '0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12';
export const MODULES_V05_ACCOUNT =
  import.meta.env.VITE_MODULES_V05_ACCOUNT ||
  '0x163df34fccbf003ce219d3f1d9e70d140b60622cb9dd47599c25fb2f797ba6e'; // mainnet
export const RESOURCES_ACCOUNT =
  import.meta.env.VITE_RESOURCES_ACCOUNT ||
  '0x05a97986a9d031c4567e15b797be516910cfcb4156312482efc6a19c0a30c948';
export const RESOURCES_V05_ACCOUNT =
  import.meta.env.VITE_RESOURCES_V05_ACCOUNT ||
  '0x61d2c22a6cb7831bee0f48363b0eec92369357aece0d1142062f7d5d85c7bef8'; // mainnet
export const RESOURCES_V1_ACCOUNT =
  import.meta.env.VITE_RESOURCES_V1_ACCOUNT ||
  '0xa0d8702b7c696d989675cd2f894f44e79361531cff115c0063390922f5463883'; // mainnet
export const MODULES_V1_ACCOUNT =
  import.meta.env.VITE_MODULES_V1_ACCOUNT ||
  '0x54cb0bb2c18564b86e34539b9f89cfe1186e39d89fce54e1cd007b8e61673a85'; // mainnet
export const COINS_ACCOUNT =
  import.meta.env.VITE_COINS_ACCOUNT ||
  '0x43417434fd869edee76cca2a4d2301e528a1551b1d719b75c350c3c97d15b8b9';
export const STAKING_ACCOUNT =
  import.meta.env.VITE_STAKING_ACCOUNT ||
  '0xb247ddeee87e848315caf9a33b8e4c71ac53db888cb88143d62d2370cca0ead2';

export const COIN_INFO = '0x1::coin::CoinInfo';
export const COIN_STORE = '0x1::coin::CoinStore';

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENABLED =
  true && SENTRY_DSN?.length && SENTRY_DSN.length > 0;

export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;

export const AVAILABLE_FUNCTIONS = {
  STAKES: true,
  APTOS_STAKING: true,
};

export const BRIDGES = ['celer', 'wormhole', 'layerzero'];

// TODO: Add UPTS token
export const MEMES_TYPES = [
  '0x73eb84966be67e4697fc5ae75173ca6c35089e802650f75422ab49a8729704ec::coin::DooDoo',
  '0xdf3d5eb83df80dfde8ceb1edaa24d8dbc46da6a89ae134a858338e1b86a29e38::coin::Returd',
  '0x55987edfab9a57f69bac759674f139ae473b5e09a9283848c1f87faf6fc1e789::shrimp::ShrimpCoin',
  '0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002',
  '0x5c738a5dfa343bee927c39ebe85b0ceb95fdb5ee5b323c95559614f5a77c47cf::Aptoge::Aptoge',
  '0x1fc2f33ab6b624e3e632ba861b755fd8e61d2c2e6cf8292e415880b4c198224d::apt20::APTS',
  '0x2778b277644d375721766abfff0df2adca795f6cbae9f02ff1c95ce9adb6ee28::aptos_shiba_coin::AptosShibaCoin',
  '0x84edd115c901709ef28f3cb66a82264ba91bfd24789500b6fd34ab9e8888e272::coin::DLC',
  '0x4fbed3f8a3fd8a11081c8b6392152a8b0cb14d70d0414586f0c9b858fcd2d6a7::UPTOS::UPTOS',
  '0xbe3c4b493632b4d776d56e19d91dcfb34f591f759f6b57f8632d455360da503c::dumdum_coin::DumdumCoin',
  '0x198e4a77b72cbcac7465e774e99d2ba552053ca57b0759ea3c008433742b4e4f::PEPE::Pepe',
  '0xe1bfc010d2bdd576036f4c1f3ea7d547f19188f5b78075dd961420d843ee914c::brew_coin::BrewCoin',
  '0x65957cb717d1ec5e13c003cbad0d20d8f7f95236ea0f8bb8c419e533eda73890::TOAST::TOAST',
  '0x2dcbc03740a6fa2efee926b9df329184cce357d0573bdab09930f4d48e61a4c8::STOS::STOS',
  '0x967adbf2e05fe665ab86a3bf2c4acfa39fbf62097963474ef70a0786dae8cfa2::NRUH::NRUH',
  '0xf6f87fb53c090da2cd681cd30eccec6825685e6f305bfb9efdbbdf31796a83a7::MONKE::MONKE',
  '0x268d4a7a2ad93274edf6116f9f20ad8455223a7ab5fc73154f687e7dbc3e3ec6::LOON::LOON',
];

export const WALLET_APP_STORE_URL =
  'https://apps.apple.com/us/app/pontem-wallet/id1643525786';
export const WALLET_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.pontemmobilewallet&hl=en&gl=US';
export const WALLET_CHROME_STORE_URL =
  'https://chrome.google.com/webstore/detail/pontem-aptos-wallet/phkbamefinggmakgklpkljjmgibohnba';
export const WALLET_ONELINK = 'https://onelink.to/t3sae5';
export const PONTEM_WALLET_NAME = 'Pontem';

export const PAGE_NAMES_WITH_ONBOARDING: RouteRecordName[] = ['swap'];

export const DELEGATION_POOL_CONTRACT_ADDRESS =
  import.meta.env.VITE_DELEGATION_POOL_CONTRACT_ADDRESS ||
  '0x1::delegation_pool';

export const TOPAZ_LINK = 'https://www.topaz.so';

export const APTOS_INDEXER_API_URL = import.meta.env.VITE_APTOS_INDEXER_API_URL;

export const TABLE_SHORT_LIST_ROWS = 5;
export const TABLE_FULL_LIST_ROWS = 500;

export const IS_PRODUCTION = window.location.host.endsWith('liquidswap.com');

const CL_PROD_URL =
  import.meta.env.VITE_CL_PROD_URL || 'https://cl.liquidswap.com/';
const CL_DEMO_URL =
  import.meta.env.VITE_CL_DEMO_URL ||
  'https://concentrated-liquidity.devops.mom/';

export const CL_URL = window.location.host.endsWith(SHORT_LIQUIDSWAP_DEMO_TAG)
  ? CL_DEMO_URL
  : CL_PROD_URL;

const LIQUIDSWAP_API_URL_PROD =
  import.meta.env.VITE_LIQUIDSWAP_API_URL_PROD || 'https://api.liquidswap.com';
const LIQUIDSWAP_API_URL_DEMO =
  import.meta.env.VITE_LIQUIDSWAP_API_URL_DEMO ||
  'https://liquidswap-api.dev.devops.mom';
export const LIQUIDSWAP_API_URL = window.location.host.endsWith(
  SHORT_LIQUIDSWAP_DEMO_TAG,
)
  ? LIQUIDSWAP_API_URL_DEMO
  : LIQUIDSWAP_API_URL_PROD;

const L2_EXPLORER_DEMO_URL =
  import.meta.env.VITE_L2_EXPLORER_DEMO_URL ||
  'https://explorer.devnet.lumio.io/';
const L2_EXPLORER_PROD_URL =
  import.meta.env.VITE_L2_EXPLORER_PROD_URL ||
  'https://explorer.testnet.lumio.io/';

const BROWSER_API_PROD_URL = 'https://browser-api.liquidswap.com/';
const BROWSER_API_DEMO_URL = 'https://browser-api.dev.devops.mom/';

export const BROWSER_API_URL = window.location.host.endsWith(
  SHORT_LIQUIDSWAP_DEMO_TAG,
)
  ? BROWSER_API_DEMO_URL
  : BROWSER_API_PROD_URL;

export const EXPLORER_URL = window.location.host.endsWith(
  SHORT_LIQUIDSWAP_DEMO_TAG,
)
  ? L2_EXPLORER_DEMO_URL
  : L2_EXPLORER_PROD_URL;

export const FARMS_PROD_URL =
  import.meta.env.VITE_FARMS_PROD_URL || 'https://farms.liquidswap.com/';
export const FARMS_DEMO_URL =
  import.meta.env.VITE_FARMS_PROD_URL || 'https://farms.devops.mom/';

export const FARM_URL = window.location.host.endsWith(SHORT_LIQUIDSWAP_DEMO_TAG)
  ? FARMS_DEMO_URL
  : FARMS_PROD_URL;

export const KANA_PROD_URL =
  import.meta.env.VITE_KANA_PROD_URL ||
  'https://bridge.liquidswap.com/kana-widget';
export const KANA_DEMO_URL =
  import.meta.env.VITE_KANA_DEMO_URL || 'https://bridge.devops.mom/kana-widget';

export const KANA_URL = window.location.host.endsWith(SHORT_LIQUIDSWAP_DEMO_TAG)
  ? KANA_DEMO_URL
  : KANA_PROD_URL;

export const FARM_BANNER_MEDIA_QUERY = '(max-width: 2450px)';

export const IS_MIXPANEL_IP_TRACKING_ENABLED =
  import.meta.env.VITE_IS_MIXPANEL_IP_TRACKING_ENABLED === '1';

export const HOW_WORK_POOL_V0 = `${BASE_URL}posts/faq-liquidity-provider-rewards-on-liquidswap`;
export const HOW_WORK_POOL_V1 = `${BASE_URL}posts/breaking-news-try-concentrated-liquidity-on-liquidswap`;
export const EXPLORER_APTOS = 'https://explorer.aptoslabs.com/';

// Current router contract revision allowed for swap function
export const MAX_SWAP_FUNCTION_AVAILABLE = 4;

export const FAVORITE_TOKENS = [LZ_USDC, DOODOO, WETH];

export const TOAST_LIFE_TIME_IN_MS = 5000;

export const SLIPPAGE = {
  DEFAULT_DIRECT_MODE_LEVEL: 0.005,
  DEFAULT_AGGREGATOR_MODE_LEVEL: 0.01,
  MIN_LEVEL_PERCENT: 0.01,
  FAIL_WARNING_LEVEL_PERCENT: 0.5,
  FRONTRUN_WARNING_LEVEL_PERCENT: 1.5,
  MAX_LEVEL_PERCENT: 50,
  STEP_PERCENT: 0.25,
  PRESET_OPTION_05: 0.5,
  PRESET_OPTION_10: 1,
  PRESET_OPTION_15: 1.5,
};

export const CLAIM_PROD_URL = import.meta.env.VITE_CLAIM_PROD_URL;

export const GEO_FENCE_BLOCKED_KEY = 'restricted';

export const APTOS_INDEXER_API_KEY = import.meta.env.VITE_APTOS_INDEXER_API_KEY;
export const APTOS_NODE_API_KEY = import.meta.env.VITE_APTOS_NODE_API_KEY;

export * from './chains';
