import { useContractVersion } from '@/composables/useContractVersion';
import { PoolsList } from '@/types/pools';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useTablePoolStore = defineStore('tablePoolStore', () => {
  const { version } = useContractVersion();
  const listPool = ref<PoolsList>('pools');
  const searchQuery = ref('');

  watch(version, () => {
    searchQuery.value = '';
  });

  return {
    version,
    listPool,
    searchQuery,
  };
});
