import { defineStore } from 'pinia';
import { tryOnMounted } from '@vueuse/shared';
import { reactive, watch, computed, ref } from 'vue';

import { VERSION_0_5, VERSION_0 } from '@/constants';
import { CURVE_STABLE, CURVE_STABLE_V05 } from '@/constants/networks';
import {
  calcReceivedLP,
  getModulesAccount,
  getResourcesAccount,
  is_sorted,
  withSlippage,
  getCurve,
} from '@/utils/contracts';
import { usePoolsStore, useStore } from '@/store';
import { ICreateToken, RegisteredPool } from '@/types';
import { useContractVersion } from '@/composables/useContractVersion';
import { usePoolExistence } from '@/composables/usePoolExistence';

const DEFAULT_SLIPPAGE = 0.005;

export const useCreatePoolStore = defineStore('createPoolStore', () => {
  const { version, setVersion: setContractVersion } = useContractVersion();
  const from = reactive<ICreateToken>({
    token: undefined,
    amount: undefined,
  });

  const to = reactive<ICreateToken>({
    token: undefined,
    amount: undefined,
  });

  const curve = ref<string>(getCurve('unstable', version.value));
  const canSwitchCurve = ref<boolean>(true);
  const poolExistence = usePoolExistence();

  const slippageIsDefault = ref(true);
  const slippage = ref(DEFAULT_SLIPPAGE);
  const convertRate = ref(0);
  const interactiveField = ref<'from' | 'to'>('from');
  const lastInteractiveField = ref<'from' | 'to'>('from');
  const isUpdatingRate = ref(false);
  const isSorted = ref(false);

  const mainStore = useStore();
  const poolsStore = usePoolsStore();

  function setCurve(curveType: string) {
    curve.value = curveType;
  }

  const receiveLp = computed<number | undefined>(() => {
    if (!from.token || !to.token || !to.amount || !from.amount) {
      return;
    }

    const value = calcReceivedLP({
      x: withSlippage(
        slippage.value,
        isSorted.value ? (from.amount as number) : (to.amount as number),
      ),
      y: withSlippage(
        slippage.value,
        isSorted.value ? (to.amount as number) : (from.amount as number),
      ),
      xReserve: 0,
      yReserve: 0,
    });

    return value;
  });

  const pool = computed<Partial<RegisteredPool> | undefined>(() => {
    if (!from.token || !to.token || !mainStore.account.value) {
      return;
    }

    const [fromToken, toToken] = isSorted.value
      ? [from.token, to.token]
      : [to.token, from.token];

    const resourceAccount = getResourcesAccount(version.value);
    const moduleAccount = getModulesAccount(version.value);

    return {
      chainId: mainStore.networkId.value,
      tokens: [fromToken, toToken],
      address: resourceAccount,
      module: moduleAccount,
      curve: curve.value,
      version: version.value,
    };
  });

  watch(slippageIsDefault, (value) => {
    if (value) {
      slippage.value = DEFAULT_SLIPPAGE;
    }
  });

  watch(
    [() => from.token, () => to.token],
    ([fromToken, toToken]) => {
      const doWeKnowCurve = poolsStore.getCurveType(
        fromToken,
        toToken,
        VERSION_0,
      );
      if (doWeKnowCurve === false) {
        canSwitchCurve.value = true;
        // we don't know pool with this tokens
        const wasStable = curve.value === getCurve('stable', version.value);
        setContractVersion(VERSION_0_5);
        curve.value = getCurve(
          wasStable ? 'stable' : 'unstable',
          version.value,
        );
      } else {
        canSwitchCurve.value = false;
        curve.value = doWeKnowCurve;
        if ([CURVE_STABLE_V05, CURVE_STABLE].includes(curve.value)) {
          // if pool with stable - we can allow to switch version
          setContractVersion(VERSION_0_5);
          curve.value = getCurve('stable', version.value);
        } else {
          setContractVersion(VERSION_0);
        }
      }
    },
    {
      deep: true,
    },
  );

  tryOnMounted(() => resetState());

  function resetState() {
    setContractVersion(0);
    from.token = mainStore.defaultToken.value;
    to.token = undefined;
    from.amount = undefined;
    to.amount = undefined;
    curve.value = getCurve('unstable', version.value);
  }

  async function refetchRates(silent = false) {
    if (!(from.token && to.token && pool.value)) {
      return;
    }

    lastInteractiveField.value = interactiveField.value;

    if (!silent) {
      isUpdatingRate.value = true;
    }

    isSorted.value = is_sorted(from.token, to.token);

    if (!mainStore.account.value) {
      if (!silent) {
        isUpdatingRate.value = false;
      }
      return;
    }

    if (!silent) {
      isUpdatingRate.value = false;
    }
  }

  async function check() {
    if (!from?.token || !to?.token) return;
    await poolExistence.check(
      {
        fromCoin: from.token,
        toCoin: to.token,
        curve: curve.value,
      },
      version.value,
    );
  }

  watch(
    [from, to, curve, version],
    async () => {
      await check();
      refetchRates(false);
    },
    {
      immediate: true,
    },
  );

  const isPoolAbsence = computed(
    () =>
      !!from.token &&
      !!to.token &&
      !poolExistence.isFetching(
        {
          fromCoin: from.token,
          toCoin: to.token,
          curve: curve.value,
        },
        version.value,
      ) &&
      !poolExistence.poolExists(
        {
          fromCoin: from.token,
          toCoin: to.token,
          curve: curve.value,
        },
        version.value,
      ),
  );

  const isBusy = computed(
    () => poolExistence.isFetching || isUpdatingRate.value,
  );

  /**
   * Create pool store public interface
   */
  return {
    version,
    curve,
    canSwitchCurve,

    check,
    isBusy,
    isPoolAbsence,
    convertRate,
    fromCurrency: from,
    interactiveField,
    isUpdatingRate,
    lastInteractiveField,
    slippageIsDefault,
    slippage,
    receiveLp,
    isSorted,
    toCurrency: to,
    refetchRates,
    pool,
    setCurve,
  };
});
