import { createApp, h, provide } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import { captureException } from '@sentry/vue';
import { SENTRY_ENABLED } from '@/constants';

import Router from './router';

import 'nprogress/nprogress.css';
import '@/styles/index.scss';

import PBadge from 'primevue/badge';
import PButton from 'primevue/button';
import Pconfirm from 'primevue/confirmpopup';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import OverlayPanel from 'primevue/overlaypanel';
import PMenu from 'primevue/menu';
import SelectButton from 'primevue/selectbutton';
import PDialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import PToast from 'primevue/toast';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import PAccordion from 'primevue/accordion';
import PAccordionTab from 'primevue/accordiontab';
import PMessage from 'primevue/message';
import PInlineMessage from 'primevue/inlinemessage';
import PChart from 'primevue/chart';
import PInputSwitch from 'primevue/inputswitch';
import PFileUpload from 'primevue/fileupload';
import Carousel from 'primevue/carousel';
import ToastService from 'primevue/toastservice';
import DataTable from 'primevue/datatable';
import Checkbox from 'primevue/checkbox';
import Panel from 'primevue/panel';

import DynamicDialog from 'primevue/dynamicdialog';
import DialogService from 'primevue/dialogservice';

import Sidebar from 'primevue/sidebar';

import i18n from '@/plugins/i18n';
import sentry from '@/plugins/sentry';
import mixpanel from '@/plugins/mixpanel';
import http from '@/plugins/http';

import { ApolloClients } from '@vue/apollo-composable';
import apollo from '@/plugins/apollo';
import {
  IS_MIXPANEL_IP_TRACKING_ENABLED,
  LIQUIDSWAP_API_URL,
} from './constants';
import VueCookies from 'vue-cookies';

const pinia = createPinia();
const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apollo,
    });
  },
  render: () => h(App),
});

app.use(VueCookies);
app.use(i18n);
app.use(sentry);
app.use(http, {
  baseURL: LIQUIDSWAP_API_URL,
  sentry: {
    isSentryEnabled: SENTRY_ENABLED,
    captureException,
  },
});
app.use(mixpanel, { ip: IS_MIXPANEL_IP_TRACKING_ENABLED });
app.use(pinia);

app.use(PrimeVue);
app.use(Router);
app.use(ToastService);
app.use(DialogService);

app.directive('tooltip', Tooltip);
app.component('PButton', PButton);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('OverlayPanel', OverlayPanel);
app.component('PMenu', PMenu);
app.component('SelectButton', SelectButton);
app.component('PConfirm', Pconfirm);
app.component('PDialog', PDialog);
app.component('BlockUI', BlockUI);
app.component('PToast', PToast);
app.component('ProgressSpinner', ProgressSpinner);
app.component('PAccordion', PAccordion);
app.component('PAccordionTab', PAccordionTab);
app.component('PMessage', PMessage);
app.component('PCarousel', Carousel);
app.component('PInlineMessage', PInlineMessage);
app.component('PChart', PChart);
app.component('PInputSwitch', PInputSwitch);
app.component('PFileUpload', PFileUpload);
app.component('CommonToast', Toast);
app.component('DynamicDialog', DynamicDialog);
app.component('PCheckbox', Checkbox);
app.component('PSidebar', Sidebar);
app.component('PDataTable', DataTable);
app.component('PPanel', Panel);
app.component('PBadge', PBadge);

app.mount('#app');
