<template>
  <p>
    {{ $t('dialogs.invalidNetwork') }}
  </p>
  <PButton
    v-if="isPontemWidget"
    class="p-button-primary w-full relative flex align-items-center justify-content-center mt-4 account-exit"
    @click="switchNetwork"
  >
    {{ $t('common.switchNetwork') }}
  </PButton>
  <PButton
    v-else
    class="p-button-primary w-full relative flex align-items-center justify-content-center mt-4 account-exit"
    @click="handleDisconnect"
  >
    {{ $t('common.disconnect') }}
  </PButton>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useWalletPlugin } from '@/composables/useWalletPlugin';

type IProps = {
  visible?: boolean;
};

defineProps<IProps>();
defineEmits(['update:visible']);

const { disconnect, wallet } = useWalletPlugin();
const dialogRef = inject('dialogRef');

const isPontemWidget = computed(() => {
  return (
    window.pontem?._client?._rpcClient?._options?.streamName ===
      'pontem-external' && wallet.value?.name.toLowerCase().includes('pontem')
  );
});

function handleDisconnect() {
  disconnect();
  dialogRef.value.close();
}

async function switchNetwork() {
  window.pontem.switchNetwork(1);

  const correctNetwork = await window.pontem.network();

  if (correctNetwork.chainId === 1) {
    dialogRef.value.close();
  }
}
</script>

<style lang="scss" scoped>
.send-button {
  width: 100%;
  margin-top: 10px;
}

.step {
  ::v-deep(.p-blockui) {
    opacity: 0;
  }
}
</style>
<style lang="scss" scoped>
.p-dialog-content {
  p {
    margin-top: 0;
    line-height: 1.4;
  }
}

.account-exit {
  font-weight: 500;
  font-size: 17px;
  padding: 15px 24px;
}
</style>
