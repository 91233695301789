import { IPersistedToken } from '@/store';

const PREFIXES: Record<string, string> = {
  // layerzero: 'lz',
  wormhole: 'wh',
  celer: 'cl',
  amnis: 'am',
};

const PREFIXES_VALUES = Object.values(PREFIXES);

export function aliasForToken(token: IPersistedToken) {
  const prefix = PREFIXES[token.source] ?? '';
  return `${prefix}${token.symbol}`;
}

export function formatAliasForToken(alias: string) {
  if (!alias) {
    return '';
  }

  for (const prefix of PREFIXES_VALUES) {
    if (alias.startsWith(prefix)) {
      return alias.substring(prefix.length);
    }
  }

  return alias;
}

const TITLES: Record<string, string> = {
  layerzero: 'LayerZero',
  wormhole: 'Wormhole',
  chainx: 'ChainX',
  celer: 'Celer',
  multichain: 'Multichain',
  amnis: 'Amnis',
};

export function titleForToken(token?: IPersistedToken) {
  if (!token) {
    return '';
  }
  const prefix = TITLES[token.source] ?? '';
  return prefix.length ? `${prefix} • ${token?.name}` : `${token.name}`;
}

export function providerForToken(token?: IPersistedToken) {
  if (!token) {
    return '';
  }
  return TITLES[token.source] ?? '';
}

/**
 * Coin registry format:
 * https://github.com/pontem-network/coins-registry/blob/main/src/coins.json
 *
 * Sentio format:
 * https://github.com/hippospace/aptos-coin-list/blob/main/src/permissionless.json
 */
const SENTIO_TO_COIN_REGISTRY_ALIASES_MAP: Record<string, string> = {
  // LayerZero
  zUSDT: 'USDT', // USDT LayerZero
  zUSDC: 'USDC', // USDC LayerZero
  zWETH: 'WETH', // Ether LayerZero

  // Celer
  ceWBTC: 'clWBTC',

  // Wormhole
  USDT: 'whUSDT',
  USDC: 'whUSDC',
  WBTC: 'whBTC',
  WETH: 'whWETH',
  SOL: 'whSOL',

  // Multichain
  multiUSDC: 'USDC',

  // Amnis
  amAPT: 'amAPT',
};

export function normalizeCoinAlias(sentioAlias: string) {
  if (!sentioAlias) {
    return '';
  }

  return SENTIO_TO_COIN_REGISTRY_ALIASES_MAP[sentioAlias] ?? sentioAlias;
}
