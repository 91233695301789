import { TokenTypes } from 'aptos';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useStore } from './useStore';
import { fetchAllAccountNfts } from '@/service/api';

export interface NftToken extends TokenTypes.TokenData {
  key: string;
  collectionName: string;
  creator: string;
  largest_property_version: string;
}

export const useNFTStore = defineStore('useNFTStore', () => {
  const store = useStore();
  const nfts = ref<NftToken[]>([]);

  // TODO: Make NFT Image with separate component with its own logic from wallet
  async function getNFTHistory() {
    const address = store.account.value?.address;

    if (!address) {
      return;
    }

    const userNFTs = await fetchAllAccountNfts(address);

    nfts.value = userNFTs as any as NftToken[];
    return nfts.value;
  }

  function getNFTFromCollection(
    collectionName: string,
    collectionOwner: string,
  ) {
    if (!Array.isArray(nfts.value) || nfts.value.length === 0) return [];
    return nfts.value.filter((item) => {
      return (
        item.collectionName === collectionName &&
        item.creator === collectionOwner
      );
    });
  }

  function getItemByKey(key: string) {
    return nfts.value.find((one) => one.key === key);
  }

  return {
    getNFTHistory,
    getNFTFromCollection,
    getItemByKey,

    nfts,
  };
});
