import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { App } from 'vue';

import {
  APP_VERSION,
  CORRECT_CHAIN,
  SENTRY_DSN,
  SENTRY_ENABLED,
} from '@/constants';
import router from '@/router';

let sentry = {
  install: (app: App) => {
    console.error('Unable to connect to Sentry', app.config);
  },
};

if (SENTRY_ENABLED) {
  sentry = {
    install: (app: App) => {
      Sentry.init({
        release: `liquidswap@${APP_VERSION}`,
        app,
        dsn: SENTRY_DSN,

        integrations: [
          //
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
        trackComponents: true,
        environment: CORRECT_CHAIN.id,
        logErrors: true,
        attachProps: true,
      });
    },
  };
}

export default sentry;
