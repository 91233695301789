import { ref, watch } from 'vue';

const DEFAULT_SLIPPAGE = 0.005;

export function useSlippage(defaultSlippage: number = DEFAULT_SLIPPAGE) {
  const isDefault = ref(true);
  const value = ref(defaultSlippage);

  watch(isDefault, (v) => {
    if (v) {
      value.value = DEFAULT_SLIPPAGE;
    }
  });

  return {
    isDefault,
    value,
  };
}
