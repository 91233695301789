import { Defer } from '@/types';
import { Decimal } from 'decimal.js';
import chunk from 'lodash/chunk';

export * from './hex';
export * from './time';
export * from './vue';
export * from './date-formatter';
export * from './swap-math';
export * from './platform';

export async function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function createDefer<T = any>(): Defer<T> {
  const defer: Partial<Defer<T>> = {
    resolve: undefined,
    reject: undefined,
    promise: undefined,
  };

  defer.promise = new Promise<T>((resolve, reject) => {
    defer.reject = reject;
    defer.resolve = resolve;
  });

  return defer as Defer<T>;
}

/**
 * Creates a Decimal instance from the given value, or returns the value if it is already a Decimal instance.
 *
 * @param {Decimal.Value} value - The value to be converted to a Decimal instance, or the existing Decimal instance.
 * @return {Decimal.Instance} The created or existing Decimal instance.
 */
export function d(value?: Decimal.Value): Decimal.Instance {
  if (Decimal.isDecimal(value)) {
    return value as Decimal;
  }
  return new Decimal(value === undefined ? 0 : value);
}

/**
 * Calculates the decimal multiplier based on the provided number of decimals.
 *
 * @param {Decimal.Value} decimals - The number of decimals to calculate the multiplier for.
 * @return {Decimal.Instance} - The decimal multiplier.
 */
export function decimalsMultiplier(decimals?: Decimal.Value): Decimal.Instance {
  return d(10).pow(d(decimals).abs());
}

export function isValidUrl(url?: string) {
  try {
    if (!url) {
      return false;
    }

    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
}

export const splitValue = (value: string) => {
  const spiltValue_ = String(value).split('.');
  const decimalLength = spiltValue_[1] && spiltValue_[1].length;
  return {
    spiltValue_,
    lastIndex: Number(decimalLength) - 1,
  };
};

export function camelCaseKeysToUnderscore(obj: any) {
  if (typeof obj !== 'object') {
    return obj;
  }

  for (const oldName in obj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase();
    });

    // Only process if names are different
    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      // eslint-disable-next-line
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Recursion
    if (typeof obj[newName] == 'object') {
      obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
    }
  }
  return obj;
}

export function getFormattedValidationCode(error: Error) {
  return JSON.parse(error.message)
    .message.split('Validation Code:')
    .pop()
    .split('_')
    .join(' ')
    .toLowerCase();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function nope() {}

export async function runParallel(
  tasks: any,
  chunkSize?: number,
): Promise<any> {
  if (chunkSize === undefined) {
    return await Promise.allSettled(tasks.map((task: any) => task()));
  }

  const result = await Promise.allSettled(
    chunk(tasks, chunkSize).map((tasks) => runSequence(tasks)),
  );

  return result;
}

export async function runSequence(tasks: any): Promise<any> {
  const res = [];
  for (const task of tasks) {
    res.push(await task());
  }

  return res;
}

export function parseBoolean(value: any): boolean {
  if (typeof value === 'string') {
    return value === 'true';
  }

  return !!value;
}
