import { CORRECT_CHAIN, MIXPANEL_TOKEN } from '@/constants';
import { EMixpanelEventNames, EMixpanelProvideKeys } from '@/types/enums';
import mixpanel, { Callback, Dict, Mixpanel, Query } from 'mixpanel-browser';

/**
 * Plugin to provide mixpanel interface to the app
 */
export default {
  /**
   * Install plugin method
   *
   * @param app
   * @param options
   */
  install: (app: any, options: any) => {
    const defaultConfiguration = {};
    const ensureObjectOptions = options ?? {};
    const configuration = Object.assign(
      ensureObjectOptions,
      defaultConfiguration,
    );

    const token = MIXPANEL_TOKEN;
    if (token) {
      mixpanel.init(token, configuration);
      app.provide(EMixpanelProvideKeys.Mixpanel, mixpanel);
    } else {
      console.warn('Mixpanel disabled. Log into console.');
    }

    /**
     * Track event to mixpanel, or log into console for dev mode
     */
    app.provide(
      EMixpanelProvideKeys.Track,
      (
        eventName: EMixpanelEventNames,
        properties?: Dict,
        options?: any,
        user_callback?: Callback,
      ): Mixpanel['track'] | void => {
        const propertiesWithNetwork = {
          ...properties,
          network: CORRECT_CHAIN.id,
        };
        if (token) {
          return mixpanel.track(
            eventName,
            propertiesWithNetwork,
            options,
            user_callback,
          );
        }

        console.info(
          'Mixpanel:',
          eventName,
          properties,
          options,
          user_callback,
        );
      },
    );
    app.provide(
      EMixpanelProvideKeys.TrackLinks,
      (
        query: Query,
        event_name: EMixpanelEventNames,
        properties?: Dict | (() => void),
      ): Mixpanel['track_links'] | void => {
        const propertiesWithNetwork = {
          ...properties,
          network: CORRECT_CHAIN.id,
        };
        console.info('Mixpanel:', query);
        if (token) {
          return mixpanel.track_links(query, event_name, propertiesWithNetwork);
        }
        console.info('Mixpanel:', event_name, propertiesWithNetwork);
      },
    );
  },
};
