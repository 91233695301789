import { TCurveType } from '@/types';
import { VERSION_0, VERSION_0_5 } from '@/constants';
import { getCurve } from '@/utils/contracts';
import { usePoolsStore } from '@/store';

export function checkIsPoolAreExceptional(
  fromToken: string,
  toToken: string,
): boolean {
  const poolsStore = usePoolsStore();

  for (const curveType of ['stable', 'unstable'] as TCurveType[]) {
    for (const version of [VERSION_0_5, VERSION_0]) {
      const curve = getCurve(curveType, version);
      const pool = poolsStore.findRegisteredPool(
        fromToken,
        toToken,
        curve,
        version,
      );

      if (pool && pool?.exceptional) {
        return true;
      }
    }
  }

  return false;
}
