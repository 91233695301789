export enum API_URL {
  /** Coins */
  COINS_REGISTERED = "/coins/registered",

  /** Farms */
  FARMS = "/farms",

  /** Indexer */

  INDEXER_USER = "/indexer/user",

  /** Pools */
  POOLS = "/pools",
  POOLS_REGISTERED = "/pools/registered",

  /** Router */
  SMART_ROUTER = "/smart-router",

  /** Sentio */
  TVL = "/sentio/tvl",
  VOLUMES = "/sentio/volumes",
  TRADING_VOLUME_BY_ADDRESS = "/sentio/user_trading_volume",
  LIQUIDITY_BY_ADDRESS = "/sentio/user_added_liq_usd",
  VOLUME_24 = "/sentio/volume24",
  TOP_VOLUME_ADDRESS = "/sentio/top_volume_address",

  /** @deprecated */
  LIQUIDITY = "/sentio/liq",
  /** @deprecated */
  TOTAL_LIQUIDITY = "/sentio/total_liq",
  /** @deprecated */
  TOTAL_SWAPS = "/sentio/total_swaps",
  /** @deprecated */
  TOTAL_SWAPS_COUNT = "/sentio/total_swaps_count",
  /** @deprecated */
  TOTAL_SWAPS_BY_ADDRESS = "/sentio/user_total_swaps",
  /** @deprecated */
  USERS = "/sentio/users",
}
