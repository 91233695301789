import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL } from "../types/urls";

export interface IApiControllerOptions {
  controllerUrl?: API_URL;
}

abstract class ApiController {
  protected readonly apiClient: AxiosInstance;
  protected readonly options: Record<string, unknown> | undefined;

  constructor(apiClient: AxiosInstance, options?: Record<string, unknown>) {
    this.apiClient = apiClient;
    this.options = options || {};
  }

  protected get<R, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.apiClient
      .get<any, AxiosResponse<R>, D>(this._getFullUrl(url), {
        ...this.options,
        ...config,
      })
      .then((response) => response.data);
  }

  protected post<R = any, D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.apiClient.post<any, R, D>(this._getFullUrl(url), data, config);
  }

  protected put<R = any, D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.apiClient.put<any, R, D>(this._getFullUrl(url), data, config);
  }

  protected delete<R = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.apiClient.delete<any, R, D>(this._getFullUrl(url), config);
  }

  private _getFullUrl(url: string): string {
    return `${url}`;
  }
}

export default ApiController;
