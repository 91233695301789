import { ChainsEnum, CORRECT_CHAIN } from '@/constants';
import { IWallet } from '@/types';
import {
  AptosWalletAdapter,
  MartianWalletAdapter,
  PontemWalletAdapter,
  FewchaWalletAdapter,
  RiseWalletAdapter,
  MSafeWalletAdapter,
  BloctoWalletAdapter,
  TrustWalletAdapter,
  TokenPocketWalletAdapter,
  WalletName,
  OkxWalletAdapter,
  BitgetWalletAdapter,
} from '@pontem/aptos-wallet-adapter';
import pontemWalletSvg from '@/assets/wallets/pontem.svg';
import petraWalletPng from '@/assets/wallets/petra.png';
import martiansWalletSvg from '@/assets/wallets/martians.svg';
import bloctoWalletSvg from '@/assets/wallets/blocto.svg';
import riseWalletSvg from '@/assets/wallets/rise.svg';
import fewchaWalletSvg from '@/assets/wallets/fewcha.svg';
import msafeWalletPng from '@/assets/wallets/msafe-green.png';
import trustWalletSvg from '@/assets/wallets/trust.svg';
import tokenPocketWalletSvg from '@/assets/wallets/tokenPocket.svg';
import okxWalletPng from '@/assets/wallets/okx.png';
import bitgetWalletPng from '@/assets/wallets/bitget.svg';

const isL2 = CORRECT_CHAIN.id === ChainsEnum['lumio-testnet'];

const ancestorsDOMList = window.location.ancestorOrigins;

let walletsList: IWallet[] = [
  {
    key: 'Pontem' as WalletName<'Pontem'>,
    label: 'Pontem Wallet',
    logo: pontemWalletSvg,
    adapter: PontemWalletAdapter,
  },
  {
    key: 'Petra' as WalletName<'Petra'>,
    label: 'Petra Wallet',
    logo: petraWalletPng,
    adapter: AptosWalletAdapter,
  },
  {
    key: 'Martian' as WalletName<'Martian'>,
    label: 'Martian Wallet',
    logo: martiansWalletSvg,
    adapter: MartianWalletAdapter,
  },
  {
    key: 'Blocto' as WalletName<'Blocto'>,
    label: 'Blocto Wallet',
    logo: bloctoWalletSvg,
    adapter: BloctoWalletAdapter,
    options: { bloctoAppId: '6d85f56e-5f2e-46cd-b5f2-5cf9695b4d46' },
  },
  {
    key: 'OKX Wallet' as WalletName<'OKX Wallet'>,
    label: 'OKX Wallet',
    logo: okxWalletPng,
    adapter: OkxWalletAdapter,
  },
  {
    key: 'Bitget Wallet' as WalletName<'Bitget Wallet'>,
    label: 'Bitget Wallet',
    logo: bitgetWalletPng,
    adapter: BitgetWalletAdapter,
  },
  {
    key: 'Rise Wallet' as WalletName<'Rise Wallet'>,
    label: 'Rise Wallet',
    logo: riseWalletSvg,
    adapter: RiseWalletAdapter,
  },
  {
    key: 'Fewcha' as WalletName<'Fewcha'>,
    label: 'Fewcha Wallet',
    logo: fewchaWalletSvg,
    adapter: FewchaWalletAdapter,
  },
  {
    key: 'MSafe' as WalletName<'MSafe'>,
    label: 'MSafe Wallet',
    logo: msafeWalletPng,
    adapter: MSafeWalletAdapter,
    options:
      CORRECT_CHAIN.id === ChainsEnum['aptos-mainnet']
        ? ancestorsDOMList?.contains('https://app.m-safe.io')
          ? 'https://app.m-safe.io/'
          : 'https://aptos.m-safe.io/'
        : ancestorsDOMList?.contains('https://testnet.m-safe.io')
        ? 'https://testnet.m-safe.io/'
        : 'https://aptos-testnet.m-safe.io/',
  },
  {
    key: 'Trust' as WalletName<'Trust'>,
    label: 'Trust Wallet',
    logo: trustWalletSvg,
    adapter: TrustWalletAdapter,
  },
  {
    key: 'TokenPocket' as WalletName<'TokenPocket'>,
    label: 'Token Pocket',
    logo: tokenPocketWalletSvg,
    adapter: TokenPocketWalletAdapter,
  },
];

if (isL2) {
  walletsList = walletsList.filter((item) =>
    ['Petra', 'Pontem'].includes(item.key),
  );
}

export { walletsList };
