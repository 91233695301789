import isFinite from 'lodash/isFinite';
import { defineStore } from 'pinia';
import {
  computed,
  inject,
  reactive,
  ref,
  Ref,
  toValue,
  watch,
  watchEffect,
} from 'vue';
import { d, decimalsMultiplier } from '@/utils';
import {
  computedWithControl,
  tryOnMounted,
  useStorage,
  useTimeoutPoll,
  watchDebounced,
} from '@vueuse/core';
import { usePoolsStore, useStore, useTokensStore } from '@/store';
import { getIsAccountCanReceiveDirectCoinTransfers } from '@/utils/liquidityApi';
import {
  composeType,
  getCurve,
  getShortCurveFromFull,
  is_sorted,
  isComplexCurve,
} from '@/utils/contracts';
import {
  DENOMINATOR,
  MAX_SWAP_FUNCTION_AVAILABLE,
  MODULES_V1_ACCOUNT,
  MULTI_ROUTER_DEFAULT,
  RECALCULATION_TIME,
  SLIPPAGE,
  VERSION_0,
  VERSION_0_5,
  VERSION_1,
} from '@/constants';
import { usePoolExistence } from '@/composables/usePoolExistence';
import { IStoredToken } from '@/types';
import { useWalletPlugin } from '@/composables/useWalletPlugin';
import { useContractVersion } from '@/composables/useContractVersion';
import { checkIsPoolAreExceptional } from '@/composables/checkIsPoolAreExceptional';

import {
  getAmountWithDecimal,
  getTokenDecimal,
} from '@/composables/useInputServices';
import { isEqual } from 'lodash';
import { useCurrencyFormat } from '@/composables/useCurrencyFormat';
import {
  ResponsePath,
  useSwapCalculations,
} from '@/composables/useSwapCalculations';
import { i18n } from '@/plugins/i18n';
import RestModule, { GetTradeRoute } from 'ls-api';
import SmartRouterError = GetTradeRoute.SmartRouterError;

type SmartRouterMode = GetTradeRoute.SmartRouterMode;
type SmartRouterResponse = GetTradeRoute.Response;
type SmartRouterQuery<T extends 'default' | 'direct'> = GetTradeRoute.Query<T>;
type Pool_V0_V05 = GetTradeRoute.Pool_V0_V05;
type Pool_V1 = GetTradeRoute.Pool_V1;
type FeeCalculationData = { symbol: string; feeAmount: number };
type Versions = typeof VERSION_0 | typeof VERSION_0_5 | typeof VERSION_1;

const initialSmartRouterResponse: SmartRouterResponse = {
  defaultMode: {
    path: [] as GetTradeRoute.Pool[],
    direct: false,
    error: '',
  },
  directMode: {
    path: [] as GetTradeRoute.Pool[],
    direct: true,
    error: '',
  },
};

class AbortSignalManager {
  private abortController: AbortController | null = null;

  newAbortSignal({ timeoutMs }: { timeoutMs?: number } = {}): AbortSignal {
    this.abortController = new AbortController();

    if (timeoutMs !== undefined) {
      setTimeout(() => this.abortController?.abort(), timeoutMs);
    }

    return this.abortController.signal;
  }

  abortRequest(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null; // Сбросить контроллер после отмены
    }
  }
}

export const useSwapStore = defineStore('swapStore', () => {
  const { getContractVersion } = useContractVersion();
  const {
    calculateDefaultModeSlippage,
    calculateDirectModeSlippage,
    calculateDefaultModePriceImpact,
    calculateDirectModePriceImpact,
    priceImpactFormatter,
    saveResponseToCache,
    getResponseFromCache,
    clearCache,
    checkIsQueryFull,
    checkIsValidResponse,
    shouldSwitchToDefaultMode,
  } = useSwapCalculations();

  const $http = inject('$http') as RestModule['controller'];

  const from = reactive<IStoredToken>({
    token: undefined,
    amount: undefined,
    rawAmount: undefined,
    decimals: 0,
    reserve: 0,
    usdEquivalent: 0,
  });

  const to = reactive<IStoredToken>({
    token: undefined,
    amount: undefined,
    rawAmount: undefined,
    decimals: 0,
    reserve: 0,
    usdEquivalent: 0,
  });

  const curve = ref<string>();
  const version = ref<Versions | undefined>();
  const stableSwapType = ref<'high' | 'normal'>('high');
  const poolExistence = usePoolExistence();

  const slippageIsDefault = ref(true);
  const slippage = ref(SLIPPAGE.DEFAULT_DIRECT_MODE_LEVEL);
  const convertRate = ref(0);
  const swapsFeeInEachPool = ref<FeeCalculationData[]>([]);
  const feeInUsd = ref<string | number>(''); // float (in percents)
  const convertFeeAmount = ref<string>('');
  const convertFee = ref(0); // float (in percents)
  /**
   * Field user currently edit
   */
  const interactiveField = ref<'from' | 'to'>('from');
  const lastInteractiveField = ref<'from' | 'to'>('from');

  const isUpdatingRate = ref(false);
  const isUpdatingPriceImpact = ref(false);
  const directCoinTransfers = ref(false);

  const priceImpact = ref(0);

  const { network } = useWalletPlugin();
  const mainStore = useStore();
  const tokensStore = useTokensStore();
  const poolsStore = usePoolsStore();
  const isFrontrunEnable = useStorage('is-frontrun-enable', false);
  const isWatcherActive = ref(true);
  const abortSignalManagerForRefetch = new AbortSignalManager();
  const abortSignalManagerForRoutes = new AbortSignalManager();
  let signalForRefetch: AbortSignal | null = null;
  let signalForRoutes: AbortSignal | null = null;
  const refetchRatesLoading = ref(false);
  const getRoutesLoading = ref(false);

  const smartRouterResponse = ref<SmartRouterResponse>({
    defaultMode: {
      path: [] as GetTradeRoute.Pool[],
      direct: false,
      error: '',
    },
    directMode: {
      path: [] as GetTradeRoute.Pool[],
      direct: true,
      error: '',
    },
  });

  const payload = reactive({
    fullRoute: [] as string[],
    curves: [] as string[],
    versions: [] as string[],
    minimumExpectedValues: [] as string[],
    swapsXForY: [] as boolean[], // for CL
    binSteps: [] as string[], // for CL
  });

  const networkId = computed(() => +(network.value?.chainId || 0));

  const predefinedCurve = computed(() => {
    return poolsStore.getCurveType(from.token, to.token, version.value);
  });

  const smartRouterMode = ref<SmartRouterMode>('direct');
  const isUserChangedMode = ref(false);

  tryOnMounted(() => resetState());

  const { pause, resume, isActive } = useTimeoutPoll(
    refetchRates,
    RECALCULATION_TIME * 2.5,
    {
      immediate: true,
    },
  );

  function resetState() {
    version.value = undefined;
    from.token = mainStore.defaultToken.value;
    to.token = undefined;
    from.amount = undefined;
    from.reserve = 0;
    to.amount = undefined;
    to.reserve = 0;
    from.usdEquivalent = undefined;
    to.usdEquivalent = undefined;
    payload.fullRoute = [];
    payload.curves = [];
    payload.versions = [];
    payload.minimumExpectedValues = [];
    swapsFeeInEachPool.value = [];
    setIsUpdatingPriceImpact(false);
    clearCache();
    Object.assign(smartRouterResponse, initialSmartRouterResponse);
  }

  function checkIsTokenAlreadyInStore(type: string) {
    return from.token === type || to.token === type;
  }

  /**
   * Sets the token in the opposite if one of the inputs already contains the value 'oppositeTokenType'.
   */
  function setOppositeToken(type: string, oppositeTokenType: string) {
    if (to.token === oppositeTokenType) {
      from.token = type;
      return;
    }

    to.token = type;
  }

  function getOppositeToken(oppositeTokenType: string) {
    if (!checkIsTokenAlreadyInStore(oppositeTokenType)) return;

    return to.token === oppositeTokenType ? from.token : to.token;
  }

  async function toggleCurrencies(
    _from: IStoredToken,
    _to: IStoredToken,
    _mode: 'from' | 'to',
    _isToggling: Ref<boolean>,
  ) {
    pause();
    isWatcherActive.value = false;
    setIsUpdatingPriceImpact(false);

    const tempFrom = JSON.parse(JSON.stringify(_from));
    const tempTo = JSON.parse(JSON.stringify(_to));

    const commonFields = {
      amount: undefined,
      rawAmount: undefined,
      decimals: 0,
      reserve: 0,
      usdEquivalent: 0,
    };

    const resetFromField = {
      ...commonFields,
      token: _from.token,
    };

    const resetToField = {
      ...commonFields,
      token: _to.token,
    };

    if (lastInteractiveField.value === 'from') {
      Object.assign(from, resetToField);
      Object.assign(to, tempFrom);
    } else {
      Object.assign(to, resetFromField);
      Object.assign(from, tempTo);
    }

    interactiveField.value = interactiveField.value === 'from' ? 'to' : 'from';
    lastInteractiveField.value = interactiveField.value;
    isWatcherActive.value = true;
    _isToggling.value = false;
    resume();
  }

  function resetRoutes() {
    Object.assign(smartRouterResponse, initialSmartRouterResponse);
    payload.fullRoute = [];
    payload.curves = [];
    payload.versions = [];
    payload.minimumExpectedValues = [];
    swapsFeeInEachPool.value = [];
    if (lastInteractiveField.value == 'from') {
      to.amount = undefined;
      to.rawAmount = undefined;
      to.reserve = 0;
    } else {
      from.amount = undefined;
      from.rawAmount = undefined;
      from.reserve = 0;
    }
    setIsUpdatingPriceImpact(false);
  }

  function updateContractPayload(response?: SmartRouterResponse[ResponsePath]) {
    if (!response) return;
    const address = MULTI_ROUTER_DEFAULT;
    const module = 'router';
    [
      payload.fullRoute,
      payload.curves,
      payload.versions,
      payload.swapsXForY,
      payload.binSteps,
      swapsFeeInEachPool.value,
    ] = response.path.reduce(
      (acc, route) => {
        const { pool } = route;

        acc[0].push(route.inputToken);
        if (pool.version_ls === `${VERSION_1}`) {
          acc[1].push(composeType(address, module, 'CurveV1'));
        } else {
          acc[1].push(getCurve((pool as Pool_V0_V05).curve, +pool.version_ls));
        }
        acc[2].push(getContractVersion(pool.version_ls));
        acc[3].push(route.inputToken === pool.x_name);
        if (pool.version_ls === `${VERSION_1}`) {
          acc[4].push(
            composeType(
              MODULES_V1_ACCOUNT,
              'bin_steps',
              `X${(pool as Pool_V1).bin_step}`,
            ),
          );
        } else {
          acc[4].push(composeType(address, module, 'BinStepV0V05'));
        }
        acc[5].push({
          symbol: route.inputToken,
          feeAmount: +route.fee,
        });
        return acc;
      },
      [[], [], [], [], [], []] as [
        string[],
        string[],
        string[],
        boolean[],
        string[],
        FeeCalculationData[],
      ],
    );

    const lastItem = response.path[response.path.length - 1];
    payload.minimumExpectedValues = calculateMinimumExpectedValues();
    payload.fullRoute.push(lastItem.outputToken);
  }

  async function getRoutes(
    _smartRouterMode: 'default' | 'direct',
    _from: IStoredToken['token'],
    _to: IStoredToken['token'],
    _version?: 0 | 0.5 | 1,
    _fullCurve?: string,
  ) {
    if (!(_from && _to)) {
      return;
    }

    const mode = lastInteractiveField.value;
    const decimals = getTokenDecimal(mode == 'from' ? _from : _to);
    if (!decimals) return;

    const responsePath: Ref<ResponsePath> = ref(
      _smartRouterMode === 'default' ? 'defaultMode' : 'directMode',
    );

    const minAmount = 0;

    const fromToken = {
      ...from,
      token: _from,
      amount: minAmount,
    };
    const toToken = {
      ...to,
      token: _to,
    };
    const query = getRouterQuery(
      _smartRouterMode,
      'from',
      fromToken,
      toToken,
      _fullCurve,
      _version,
    );
    const isFullQuery = checkIsQueryFull(query);

    if (!isFullQuery) {
      return;
    }

    const cached = getResponseFromCache(
      query as SmartRouterQuery<'default' | 'direct'>,
    );

    getRoutesLoading.value = true;
    signalForRoutes = abortSignalManagerForRoutes.newAbortSignal();
    const response: SmartRouterResponse | undefined = await $http.getRoute(
      query as SmartRouterQuery<'default' | 'direct'>,
      0,
      signalForRoutes,
    );

    if (response && response.directMode.error) {
      responsePath.value = 'defaultMode';
      setSmartRouterMode('default');
    }

    const validResponse = checkIsValidResponse(smartRouterMode, response);

    const validCached = checkIsValidResponse(smartRouterMode, cached);

    const responseByMode =
      response && validResponse && response[responsePath.value];
    signalForRoutes = null;
    getRoutesLoading.value = false;
    const cachedByMode = cached && validCached && cached[responsePath.value];

    const responseLastItem:
      | SmartRouterResponse[ResponsePath]['path'][number]
      | undefined =
      response && responseByMode && Array.isArray(responseByMode.path)
        ? responseByMode?.path[responseByMode?.path?.length - 1]
        : undefined;

    const cachedLastItem:
      | SmartRouterResponse[ResponsePath]['path'][number]
      | undefined =
      cached && cachedByMode && Array.isArray(cachedByMode.path)
        ? cachedByMode?.path?.[cachedByMode?.path?.length - 1]
        : undefined;

    if (!validResponse && validCached) {
      smartRouterResponse.value = cached as SmartRouterResponse;
      if (smartRouterMode.value === 'direct') {
        setReserves(
          cachedLastItem as SmartRouterResponse['directMode']['path'][number],
        );
      } else {
        clearReserves();
      }
      return;
    }

    if (response) {
      smartRouterResponse.value = response as SmartRouterResponse;
      if (smartRouterMode.value === 'direct' && responseLastItem) {
        setReserves(
          responseLastItem as SmartRouterResponse['directMode']['path'][number],
        );
      } else {
        clearReserves();
      }
      // setRateFromResponse();
    }
  }

  async function refetchRates(silent = false): Promise<void> {
    if (!(from.token && to.token) || !isWatcherActive.value) return;

    lastInteractiveField.value = interactiveField.value;
    if (!silent) {
      isUpdatingRate.value = true;
    }
    const mode = lastInteractiveField.value;

    const _amount = mode == 'from' ? from.amount : to.amount;
    const inputOrOutputResponse =
      mode == 'from' ? 'outputAmount' : 'inputAmount';
    const resultField = mode == 'from' ? to : from;
    const _smartRouterMode = toValue(smartRouterMode);

    const query = getRouterQuery(
      _smartRouterMode,
      mode,
      from,
      to,
      curve.value,
      version.value,
    );
    const isFullQuery = checkIsQueryFull(query);

    if (_amount === undefined || _amount === 0 || !isFullQuery) {
      setIsUpdatingPriceImpact(false);
      resultField.amount = undefined;
      resultField.rawAmount = undefined;
      isUpdatingRate.value = false;
      refetchRatesLoading.value = false;
      return;
    }

    const responsePath: Ref<ResponsePath> = ref(
      smartRouterMode.value === 'default' ? 'defaultMode' : 'directMode',
    );

    const cached = getResponseFromCache(
      query as SmartRouterQuery<'default' | 'direct'>,
    );

    signalForRefetch = abortSignalManagerForRefetch.newAbortSignal();
    refetchRatesLoading.value = true;
    const response = await $http.getRoute(
      query as SmartRouterQuery<'default' | 'direct'>,
      0,
      signalForRefetch,
    );
    refetchRatesLoading.value = false;

    const validResponse = checkIsValidResponse(smartRouterMode, response);
    signalForRefetch = null;

    const validCached = checkIsValidResponse(smartRouterMode, cached);

    const responseByMode =
      response && validResponse && response[responsePath.value];
    const cachedByMode = cached && validCached && cached[responsePath.value];

    const responseLastItem:
      | SmartRouterResponse[ResponsePath]['path'][number]
      | undefined =
      response && responseByMode && Array.isArray(responseByMode.path)
        ? responseByMode?.path[responseByMode?.path?.length - 1]
        : undefined;

    const cachedLastItem:
      | SmartRouterResponse[ResponsePath]['path'][number]
      | undefined =
      cached && cachedByMode && Array.isArray(cachedByMode.path)
        ? cachedByMode?.path?.[cachedByMode?.path?.length - 1]
        : undefined;

    if (!validResponse && validCached) {
      smartRouterResponse.value = cached as SmartRouterResponse;
      resultField.amount = Number(cachedLastItem?.[inputOrOutputResponse]);
      setIsUpdatingPriceImpact(false);
      isUpdatingRate.value = false;
      return;
    }

    if (response) {
      saveResponseToCache(
        response as SmartRouterResponse,
        query as SmartRouterQuery<'default' | 'direct'>,
      );
      smartRouterResponse.value = response;

      if (smartRouterMode.value === 'direct' && responseLastItem) {
        setReserves(responseLastItem);
      } else {
        clearReserves();
      }

      if (responseByMode) {
        updateContractPayload(responseByMode);
      }

      if (responseLastItem) {
        setRateFromResponse();
        setFeeInCoins(+responseLastItem.fee);
        setFeePercent(responseLastItem.pool.fee);
      }

      const toDec = d(to.amount).div(
        decimalsMultiplier(tokensStore.tokens[to.token].decimals),
      );
      const fromDec = d(from.amount).div(
        decimalsMultiplier(tokensStore.tokens[from.token].decimals),
      );

      convertRate.value = +Number(
        toDec
          .div(fromDec)
          .mul(decimalsMultiplier(tokensStore.tokens[to.token].decimals))
          .toFixed(0),
      );

      if (response && response.directMode.error) {
        responsePath.value = 'defaultMode';
        setSmartRouterMode('default');
      }

      if (
        response &&
        shouldSwitchToDefaultMode(response, mode) &&
        !isUserChangedMode.value
      ) {
        setSmartRouterMode('default');
      }
    } else {
      resetRoutes();
    }

    isUpdatingRate.value = false;
  }

  function setFeeInUsd(value: string | number) {
    feeInUsd.value = value;
  }

  function setFeeInCoins(fee: number | string) {
    convertFeeAmount.value =
      from?.amount && from.amount > 0
        ? useCurrencyFormat(fee, from.token, {
            useSuffix: true,
          }).formatted.value
        : '0';
  }

  function setFeePercent(fee: string) {
    convertFee.value = fee ? (+fee * 100) / DENOMINATOR : 0;
  }

  function setUsdEquivalent(mode?: 'from' | 'to', value?: number) {
    if (mode === 'from') {
      from.usdEquivalent = value;
    }
    if (mode === 'to') {
      to.usdEquivalent = value;
    }
  }

  function setSmartRouterMode(mode: SmartRouterMode, isUserChanged = false) {
    smartRouterMode.value = mode;
    isUserChangedMode.value = isUserChanged;
    const responseByMode = getResponseByMode(mode);

    setIsUpdatingPriceImpact(false);

    if (mode === 'direct') {
      setReserves(responseLastItem.value);
    }

    setRateFromResponse();
    updateContractPayload(responseByMode);
    priceImpactCalculation.trigger();
  }

  function setReserves(
    pathPart?: SmartRouterResponse['directMode']['path'][number] | null,
  ) {
    if (
      !pathPart ||
      !pathPart.pool.x_val ||
      !pathPart.pool.y_val ||
      !from.token ||
      !to.token
    ) {
      return;
    }

    const { pool } = pathPart;
    from.reserve = is_sorted(pathPart.inputToken, pathPart.outputToken)
      ? +pool.x_val
      : +pool.y_val;
    to.reserve = is_sorted(pathPart.inputToken, pathPart.outputToken)
      ? +pool.y_val
      : +pool.x_val;
  }

  function clearReserves() {
    from.reserve = 0;
    to.reserve = 0;
  }

  function setCurve(complexCurveType?: string) {
    if (!complexCurveType || !isComplexCurve(complexCurveType)) {
      console.log(`Invalid curve type ${complexCurveType}`);
      return;
    }
    curve.value = complexCurveType;
  }

  function setVersion(ver: Versions | undefined) {
    if (
      ver === undefined ||
      (ver !== VERSION_0 && ver !== VERSION_0_5 && ver !== VERSION_1)
    ) {
      console.log(`Invalid version ${ver}`);
      return;
    }
    version.value = ver;
  }

  function setIsUpdatingPriceImpact(value: boolean) {
    isUpdatingPriceImpact.value = value;
  }

  function calculateMinimumExpectedValues(): string[] {
    if (
      !swappingRoutes.value ||
      !Array.isArray(swappingRoutes.value) ||
      swappingRoutes.value.length === 0 ||
      to.amount === undefined ||
      from.amount === undefined
    )
      return [];
    if (lastInteractiveField.value === 'from') {
      const result = Array(swappingRoutes.value.length).fill('0');
      result[result.length - 1] = slippageAmount.value;
      return result;
    } else {
      return swappingRoutes.value.map((route) => route.outputAmount);
    }
  }

  function getRouterQuery(
    _smartRouterMode: 'direct' | 'default',
    _inputMode: 'from' | 'to',
    _from: IStoredToken,
    _to: IStoredToken,
    _fullCurve?: string,
    _version?: Versions,
  ) {
    if (
      !_from.token ||
      !_to.token ||
      !_inputMode ||
      !_fullCurve ||
      _version === undefined
    )
      return;

    const requestField = _inputMode == 'from' ? 'input' : 'output';
    const _amount = _inputMode == 'from' ? `${_from.amount}` : `${_to.amount}`;

    const stableCurve = getCurve('stable', _version);
    const unstableCurve = getCurve('unstable', _version);
    const localFullCurve =
      _fullCurve === stableCurve || _fullCurve === 'stable'
        ? stableCurve
        : unstableCurve;

    const basePayload = {
      from: from.token || '',
      to: to.token || '',
    };

    const defaultQuery: SmartRouterQuery<'default'> = {
      ...basePayload,
      cl: true,
      [requestField]: _amount,
    };

    const directQuery: SmartRouterQuery<'direct'> = {
      ...basePayload,
      version: `${_version}`,
      curve: getShortCurveFromFull(localFullCurve) || 'unstable',
      cl: true,
      [requestField]: _amount,
    };

    return _smartRouterMode === 'default' ? defaultQuery : directQuery;
  }

  function setRateFromResponse() {
    const resultField = lastInteractiveField.value == 'from' ? to : from;
    if (!responseLastItem.value || !responseFirstItem.value) {
      return;
    }

    const rate = Number(
      lastInteractiveField.value === 'from'
        ? responseLastItem.value.outputAmount
        : responseFirstItem.value.inputAmount,
    );
    if (!rate || rate <= 0 || !isFinite(Number(rate))) {
      return;
    }
    resultField.amount = Number(rate);
    resultField.decimals = getTokenDecimal(resultField.token) || 0;
    resultField.rawAmount = getAmountWithDecimal(rate, resultField.decimals);
  }

  function getResponseByMode(mode?: SmartRouterMode) {
    const _mode = mode || smartRouterMode.value;
    const responsePath: ResponsePath =
      _mode === 'default' ? 'defaultMode' : 'directMode';
    return smartRouterResponse.value[responsePath];
  }

  async function check() {
    if (!from?.token || !to?.token || !curve.value) return;

    await poolExistence.check(
      {
        fromCoin: from.token,
        toCoin: to.token,
        curve: curve.value,
      },
      version.value,
    );
  }

  const slippageAmount = computed(() => {
    return smartRouterMode.value === 'default'
      ? calculateDefaultModeSlippage({
          mode: lastInteractiveField.value,
          from,
          to,
          slippage,
        })
      : calculateDirectModeSlippage({
          mode: lastInteractiveField.value,
          from,
          to,
          slippage,
          curve,
          version,
        });
  });

  const swappingRoutes = computed<GetTradeRoute.Pool[] | null>(() => {
    if (smartRouterMode.value === 'default') {
      return smartRouterResponse.value?.defaultMode?.path || null;
    } else {
      return smartRouterResponse.value?.directMode?.path || null;
    }
  });

  const responseLastItem = computed<
    SmartRouterResponse[ResponsePath]['path'][number] | null
  >(() => {
    const isDirectMode = smartRouterMode.value === 'direct';
    const responsePath = isDirectMode
      ? smartRouterResponse.value?.directMode?.path
      : smartRouterResponse.value?.defaultMode?.path;
    const isValidResponse =
      responsePath && Array.isArray(responsePath) && responsePath.length > 0;
    return isValidResponse ? responsePath[responsePath.length - 1] : null;
  });

  const responseFirstItem = computed<
    SmartRouterResponse[ResponsePath]['path'][number] | null
  >(() => {
    const isDirectMode = smartRouterMode.value === 'direct';
    const responsePath = isDirectMode
      ? smartRouterResponse.value?.directMode?.path
      : smartRouterResponse.value?.defaultMode?.path;
    const isValidResponse =
      responsePath && Array.isArray(responsePath) && responsePath.length > 0;
    return isValidResponse ? responsePath[0] : null;
  });

  const currentModeError = computed(() => {
    const mode = interactiveField.value;
    const amountTooLow = 'amount too low';
    const error =
      smartRouterMode.value === 'default'
        ? smartRouterResponse.value?.defaultMode?.error || ''
        : smartRouterResponse.value?.directMode?.error || '';

    if (error === SmartRouterError.InsufficientFunds) {
      return {
        state: error,
        text: i18n.t('swap.insufficientFunds'),
      };
    }

    if (error === SmartRouterError.RouteNotFound) {
      return {
        state: error,
        text:
          smartRouterMode.value === 'default'
            ? i18n.t('swap.notFound')
            : i18n.t('swap.poolIsNotCreated'),
      };
    }

    if (
      mode === 'from' &&
      from?.amount &&
      from?.amount > 0 &&
      (to?.amount === 0 || to.amount === undefined) &&
      responseLastItem?.value?.outputAmount === '0' &&
      !refetchRatesLoading.value
    ) {
      return {
        state: amountTooLow,
        text: i18n.t('errors.amountTooLow'),
      };
    }

    if (
      mode === 'to' &&
      to?.amount &&
      to?.amount > 0 &&
      (from?.amount === 0 || from.amount === undefined) &&
      responseFirstItem?.value?.inputAmount === '0' &&
      !refetchRatesLoading.value
    ) {
      return {
        state: amountTooLow,
        text: i18n.t('errors.amountTooLow'),
      };
    }

    return {
      state: null,
      text: '',
    };
  });

  const isPoolAbsence = computed((): boolean => {
    return (
      smartRouterMode.value === 'direct' &&
      currentModeError.value.state === SmartRouterError.RouteNotFound
    );
  });

  /**
   * Checks whether the selected pool is exceptional or not
   */
  const isPoolExceptional = computed((): boolean => {
    if (!from.token || !to.token) {
      return false;
    }
    return checkIsPoolAreExceptional(from.token, to.token);
  });

  const isBusy = computed(() => isUpdatingRate.value);

  const priceImpactCalculation = computedWithControl(
    [() => from.usdEquivalent, () => to.usdEquivalent],
    () => {
      const {
        usdEquivalent: fromUsd,
        amount: fromAmount,
        reserve: fromReserve,
      } = from;
      const { usdEquivalent: toUsd, amount: toAmount, reserve: toReserve } = to;

      if (smartRouterMode.value === 'default') {
        return calculateDefaultModePriceImpact({
          fromUsd,
          toUsd,
          fromAmount,
          toAmount,
        });
      } else {
        return calculateDirectModePriceImpact({
          fromAmount,
          fromReserve,
          toReserve,
        });
      }
    },
  );

  const priceImpactFormatted = computed(() => {
    if (
      priceImpact.value === 0 ||
      Number.isNaN(priceImpact.value) ||
      !Number.isFinite(priceImpact.value)
    )
      return priceImpactFormatter.format(0);
    return priceImpactFormatter.format(priceImpact.value);
  });

  const priceImpactState = computed(() => {
    if (!priceImpact.value) return 'normal';
    if (+priceImpactFormatted.value <= 10) return 'normal';
    if (+priceImpactFormatted.value >= 10 && +priceImpactFormatted.value < 20)
      return 'warning';
    return 'alert';
  });

  const swapFunctionName = computed(() => {
    const address = MULTI_ROUTER_DEFAULT;
    const module = 'router';

    if (
      payload.fullRoute.length - 1 > MAX_SWAP_FUNCTION_AVAILABLE ||
      payload.fullRoute.length === 0
    ) {
      return '';
    }
    const currentName =
      lastInteractiveField.value === 'from'
        ? 'swap_exact_coin_for_coin_'
        : 'swap_coin_for_exact_coin_';

    const struct = `${currentName}x${payload.fullRoute.length - 1}`;

    return composeType(address, module, struct);
  });

  const directBinStep = computed(() => {
    const isValidResponse =
      smartRouterMode.value === 'direct' &&
      smartRouterResponse.value &&
      Array.isArray(smartRouterResponse.value.directMode.path) &&
      smartRouterResponse.value.directMode.path.length === 1 &&
      smartRouterResponse.value.directMode.path[0].pool.version_ls ===
        `${VERSION_1}`;
    if (isValidResponse) {
      const binStep = (
        smartRouterResponse.value.directMode.path[0].pool as Pool_V1
      ).bin_step;
      return `X${binStep}`;
    }
    return '';
  });

  watch(slippageIsDefault, (value) => {
    if (value) {
      slippage.value =
        smartRouterMode.value === 'default'
          ? SLIPPAGE.DEFAULT_AGGREGATOR_MODE_LEVEL
          : SLIPPAGE.DEFAULT_DIRECT_MODE_LEVEL;
    }
  });

  watchEffect(() => {
    if (mainStore.account.value) {
      getIsAccountCanReceiveDirectCoinTransfers(mainStore.account.value.address)
        .then((response: any) => {
          if (response) {
            directCoinTransfers.value = response[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      directCoinTransfers.value = false;
    }
  });

  watch(tokensStore.tokens, (newTokens, oldTokens) => {
    const isEqualTokens = isEqual(newTokens, oldTokens);
    if (isEqualTokens) return;

    from.token =
      tokensStore.getToken(from.token)?.type || mainStore.defaultToken.value;
    to.token =
      from.token !== tokensStore.getToken(to.token)?.type
        ? tokensStore.getToken(to.token)?.type
        : undefined;
  });

  watch(
    () => mainStore.walletName.value,
    () => {
      if (mainStore.walletName.value.toLowerCase().includes('pontem')) {
        isFrontrunEnable.value = false;
      }
    },
    {
      immediate: true,
    },
  );

  watchDebounced(
    () => [
      interactiveField.value,
      from.amount,
      to.amount,
      from.token,
      to.token,
      version.value,
      curve.value,
      smartRouterMode.value,
    ],
    async (_newValue, _oldValue) => {
      const [
        newMode,
        newFromAmount,
        newToAmount,
        newFromToken,
        newToToken,
        newVersion,
        newCurve,
      ] = _newValue as [
        newMode: 'from' | 'to',
        newFromAmount: number | undefined,
        newToAmount: number | undefined,
        newFromToken: string | undefined,
        newToToken: string | undefined,
        newVersion:
          | typeof VERSION_0
          | typeof VERSION_0_5
          | typeof VERSION_1
          | undefined,
        newCurve: string | undefined,
      ];

      const [
        oldMode,
        oldFromAmount,
        oldToAmount,
        oldFromToken,
        oldToToken,
        oldVersion,
        oldCurve,
      ] =
        (_oldValue as [
          oldMode: 'from' | 'to',
          oldFromAmount: number | undefined,
          oldToAmount: number | undefined,
          oldFromToken: string | undefined,
          oldToToken: string | undefined,
          oldVersion:
            | typeof VERSION_0
            | typeof VERSION_0_5
            | typeof VERSION_1
            | undefined,
          oldCurve: string | undefined,
        ]) || [];

      if (newFromToken === oldFromToken && newToToken === oldToToken) {
        return;
      }

      const isDirectMode = smartRouterMode.value === 'direct';
      const isDefaultMode = smartRouterMode.value === 'default';

      const isNewModeFrom = newMode === 'from';
      const isNewModeTo = newMode === 'to';

      const responseTokenByMode = isNewModeFrom ? 'outputToken' : 'inputToken';

      let responseToken;
      if (responseLastItem.value) {
        responseToken = responseLastItem.value[responseTokenByMode];
      }

      const tokensAreSame =
        newFromToken === responseToken || newToToken === responseToken;

      const amountsAreEmpty =
        newFromAmount === undefined && newToAmount === undefined;

      const isDirectModeInvalid =
        isDirectMode &&
        tokensAreSame &&
        (!newFromToken ||
          !newToToken ||
          newVersion === undefined ||
          !newCurve ||
          !amountsAreEmpty);

      const isDefaultModeInvalid =
        isDefaultMode && (!newFromToken || !newToToken || !amountsAreEmpty);

      const isNewModeFromInvalid =
        isNewModeFrom && !amountsAreEmpty && tokensAreSame;

      const isNewModeToInvalid =
        isNewModeTo && !amountsAreEmpty && tokensAreSame;

      if (
        isDirectModeInvalid ||
        isDefaultModeInvalid ||
        isNewModeFromInvalid ||
        isNewModeToInvalid
      ) {
        return;
      }

      from.decimals = getTokenDecimal(newFromToken as string) || 0;
      to.decimals = getTokenDecimal(newToToken as string) || 0;

      resetRoutes();
      if (getRoutesLoading.value) {
        abortSignalManagerForRoutes?.abortRequest();
        getRoutesLoading.value = false;
      }

      isUserChangedMode.value = false;

      await getRoutes(
        smartRouterMode.value,
        newFromToken as string,
        newToToken as string,
        newVersion as 0 | 0.5 | 1,
        newCurve as string,
      );
    },
    {
      debounce: 300,
      maxWait: undefined,
      deep: true,
      immediate: true,
    },
  );

  watchDebounced(
    () => [
      interactiveField.value,
      from.amount,
      to.amount,
      from.token,
      to.token,
      version.value,
      curve.value,
    ],
    async (_newValue, _oldValue) => {
      const [
        _mode,
        _fromAmount,
        _toAmount,
        _fromToken,
        _toToken,
        _version,
        ,
        _curve,
      ] = _newValue;
      const [
        _oldMode,
        _oldFromAmount,
        _oldToAmount,
        _oldFromToken,
        _oldToToken,
        _oldVersion,
        ,
        _oldCurve,
      ] = _oldValue || [];

      const isModeFromAndDFromChanged =
        _mode === 'from' &&
        ((_fromAmount !== undefined && _fromAmount !== _oldFromAmount) ||
          _version !== _oldVersion ||
          _curve !== _oldCurve);
      const isModeToAndToChanged =
        _mode === 'to' &&
        ((_toAmount !== undefined && _toAmount !== _oldToAmount) ||
          _version !== _oldVersion ||
          _curve !== _oldCurve);

      if (!isModeFromAndDFromChanged && !isModeToAndToChanged) {
        return;
      }
      if (refetchRatesLoading.value) {
        abortSignalManagerForRefetch?.abortRequest();
        refetchRatesLoading.value = false;
      }
      await refetchRates();
    },
    {
      debounce: 500,
      maxWait: undefined,
      deep: true,
      immediate: true,
      rejectOnCancel: true,
    },
  );

  watch(slippage, (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      updateContractPayload(getResponseByMode(smartRouterMode.value));
    }
  });

  watchDebounced(
    () => priceImpactCalculation.value,
    (value) => {
      priceImpact.value = value;
      setIsUpdatingPriceImpact(false);
    },
    { debounce: smartRouterMode.value === 'default' ? 1000 : 0 },
  );

  watch([currentModeError, isUpdatingPriceImpact], (newValue) => {
    const [newModeError, newIsUpdatingPriceImpact] = newValue;
    if (!newModeError.state && newIsUpdatingPriceImpact) {
      setIsUpdatingPriceImpact(false);
    }
  });

  watch(smartRouterMode, () => {
    slippage.value =
      smartRouterMode.value === 'default'
        ? SLIPPAGE.DEFAULT_AGGREGATOR_MODE_LEVEL
        : SLIPPAGE.DEFAULT_DIRECT_MODE_LEVEL;
  });

  return {
    version,
    check,
    isBusy,
    isPoolAbsence,
    convertRate,
    convertFee,
    convertFeeAmount,
    fromCurrency: from,
    interactiveField,
    isUpdatingRate,
    lastInteractiveField,
    networkId,
    slippage,
    slippageAmount,
    slippageIsDefault,
    toCurrency: to,
    curve,
    toggleCurrencies,
    refetchRates,
    poolExists: poolExistence.poolExists,
    stableSwapType,
    priceImpact,
    isFrontrunEnable,
    priceImpactState,
    priceImpactFormatted,
    predefinedCurve,
    isPoolExceptional,
    directCoinTransfers,
    checkIsTokenAlreadyInStore,
    setOppositeToken,
    getOppositeToken,
    swappingRoutes,
    payload,
    swapFunctionName,
    swapsFeeInEachPool,
    setFeeInCoins,
    setFeeInUsd,
    feeInUsd,
    setUsdEquivalent,
    setSmartRouterMode,
    smartRouterMode,
    pauseSmartRouter: pause,
    resumeSmartRouter: resume,
    isSmartRouterActive: isActive,
    setCurve,
    setVersion,
    setIsUpdatingPriceImpact,
    isUpdatingPriceImpact,
    smartRouterResponse,
    currentModeError,
    directBinStep,
  };
});
