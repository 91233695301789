import { Ref, reactive, watch } from 'vue';

import { useAptosClient } from '@/composables/useAptosClient';

import { ICreateToken, IPoolExist } from '@/types';
import { usePoolsStore } from '@/store';
import { getPoolStr } from '@/utils/pools';
interface IPoolExistence {
  exists: boolean;
  isFetching: boolean;
}

function getPoolExistenceKey(params: IPoolExist, contract?: number) {
  return `${params.fromCoin}|${params.toCoin}|${params.curve}|${contract}`;
}

const poolExistenceMap = reactive<Record<string, IPoolExistence>>({});

export function usePoolExistence() {
  const aptos = useAptosClient();

  function getPoolExistenceEntity(params: IPoolExist, contract?: number) {
    const poolExistenceKey = getPoolExistenceKey(params, contract);

    return poolExistenceMap[poolExistenceKey] ?? {};
  }

  function poolExists(params: IPoolExist, contract?: number) {
    return getPoolExistenceEntity(params, contract).exists;
  }

  function isFetching(params: IPoolExist, contract?: number) {
    return getPoolExistenceEntity(params, contract).isFetching;
  }

  async function checkExistence(
    params: IPoolExist,
    contract?: number,
  ): Promise<boolean> {
    const { fromCoin, toCoin, curve } = params;
    const poolsStore = usePoolsStore();

    const poolStr = getPoolStr(fromCoin, toCoin, curve, contract);

    if (poolsStore.poolsMap[poolStr]) {
      return true;
    }

    const poolExistenceKey = getPoolExistenceKey(params, contract);

    if (!poolExistenceMap[poolExistenceKey]) {
      poolExistenceMap[poolExistenceKey] = {
        exists: false,
        isFetching: false,
      };
    }

    poolExistenceMap[poolExistenceKey].isFetching = true;
    const res = await aptos.client.getPoolExistence(params, contract);

    poolExistenceMap[poolExistenceKey].isFetching = false;
    return res;
  }

  async function check(params: IPoolExist, contract?: number) {
    const poolExistenceKey = getPoolExistenceKey(params, contract);

    if (!poolExistenceMap[poolExistenceKey]) {
      poolExistenceMap[poolExistenceKey] = {
        exists: false,
        isFetching: false,
      };
    }

    poolExistenceMap[poolExistenceKey].exists = await checkExistence(
      params,
      contract,
    );

    return poolExistenceMap[poolExistenceKey].exists
      ? poolExistenceKey.split('|')
      : false;
  }

  function reset(params: IPoolExist, contract?: number) {
    const poolExistenceKey = getPoolExistenceKey(params, contract);

    if (!poolExistenceMap[poolExistenceKey]) {
      poolExistenceMap[poolExistenceKey] = {
        exists: false,
        isFetching: false,
      };
    }

    poolExistenceMap[poolExistenceKey].exists = false;
  }

  function watchChanges(
    from: ICreateToken,
    to: ICreateToken,
    curve: Ref<string>,
    contract?: Ref<number>,
  ) {
    watch(
      [from.token, to.token, curve.value, contract?.value],
      async () => {
        if (!from.token || !to.token) {
          reset(
            {
              fromCoin: from.token ?? '',
              toCoin: to.token ?? '',
              curve: curve.value,
            },
            contract?.value,
          );
        } else {
          await check(
            {
              fromCoin: from.token,
              toCoin: to.token,
              curve: curve.value,
            },
            contract?.value,
          );
        }
      },
      {
        immediate: true,
      },
    );
  }

  return {
    isFetching,
    poolExists,
    check,
    watch: watchChanges,
    reset,
  };
}
