<template>
  <div
    :class="{
      'app-container_not-found': isNotFoundPage,
      'app-container': !isNotFoundPage && !isLsdStakingPage,
      'app-container_lsd-staking': isLsdStakingPage,
    }"
  >
    <router-view v-slot="{ Component }">
      <transition :name="$route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>
    <PToast group="tl" position="top-left" />
    <!-- Following toast is for beta test only, should be removed after   -->
    <!-- TODO: Remove after tests   -->
    <PToast group="tr" position="top-right">
      <template #message="{ message }">
        <i class="pi pi-info-circle beta-icon"></i>

        <div class="p-toast-content">
          <div class="p-toast-message">{{ message.summary }}</div>
          <div class="p-toast-detail">{{ message.detail }}</div>
        </div>
      </template>
    </PToast>
    <div
      v-if="false"
      data-tf-button-color="#0445AF"
      data-tf-button-text="Launch me"
      data-tf-iframe-props="title=AMM Feedback Form"
      data-tf-medium="snippet"
      data-tf-popover="tUYgFq4u"
      style="all: unset"
    />
    <DynamicDialog />
  </div>
</template>

<script lang="ts" setup>
import {
  usePoolsStore,
  usePoolsV1Store,
  useStore,
  useSwapStore,
  useTokensStore,
} from '@/store';
import { computed, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useStakingStore } from '@/store/useStakingStore';
import { EMixpanelEventNames, EMixpanelProvideKeys } from './types/enums';
import { useI18n } from '@/composables/useI18n';
import { fetch } from '@/utils/fetch';
import { AIRDROP_API } from '@/constants';
import { storeToRefs } from 'pinia';
import { InvalidNetworkDialog } from '@/components/Dialogs/InvalidNetworkDialog';
import { useDialog } from 'primevue/usedialog';
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import { useWalletPlugin } from '@/composables/useWalletPlugin';
import { CORRECT_CHAIN } from '@/constants/chains';

const { t } = useI18n();
const toast = useToast();

const route = useRoute();

const mainStore = useStore();
const { fetchCoinsData, setIsInitialized: setIsTokensStoreInitialized } =
  useTokensStore();
const { fetchPools } = usePoolsV1Store();
const poolsStore = usePoolsStore();
const swapStore = useSwapStore();
const { network } = useWalletPlugin();

const { smartRouterMode } = storeToRefs(swapStore);

// initialize stores
(async () => {
  await Promise.all([
    fetchCoinsData().then(() => {
      setIsTokensStoreInitialized(true);
    }),
    fetchPools(),
    checkLoadPools(),
    useSwapStore(),
  ]).catch((err) => {
    console.error('Error during stores initialization', err);
  });
})();

const track: any = inject(EMixpanelProvideKeys.Track);

const isNotFoundPage = computed(() => route.name === 'not-found');
const isLsdStakingPage = computed(() => route.name === 'lsd-staking');

const dialog = useDialog();
let dialogRef: DynamicDialogInstance;

const getReferralId = async () => {
  try {
    const response = await fetch(
      `${AIRDROP_API}/airdrop/aptos/${mainStore.account.value.address}`,
    );
    mainStore.referralId.value = response.id;
  } catch (error) {
    console.log(error);
  } finally {
    mainStore.referralIdLoading.value = false;
  }
};

onMounted(async () => {
  const activeUserTimeout = 5000;
  setTimeout(() => {
    track(EMixpanelEventNames.ActiveUser);
  }, activeUserTimeout);

  const stakingStore = useStakingStore();
  await stakingStore.fetchPoolsList();
});

onBeforeUnmount(() => {
  removeBetaWarningToast();
});

watch(
  () => mainStore.account?.value,
  (newValue) => {
    if (newValue?.address) {
      getReferralId();
    }
  },
  {
    deep: true,
  },
);

watch(
  () => mainStore.account.value?.address,
  () => {
    if (
      mainStore.account.value?.address &&
      window?.MetaCRMWidget?.manualConnectWallet
    ) {
      window.MetaCRMWidget.manualConnectWallet(mainStore.account.value.address);

      const handleConnectWidget = () => {
        window.MetaCRMWidget.manualConnectWallet(
          mainStore.account.value?.address,
        );
      };
      document.addEventListener('MetaCRMLoaded', handleConnectWidget);

      return () => {
        document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
      };
    } else {
      window.MetaCRMWidget.manualConnectWallet(null);
    }
  },
);

watch(
  () => network.value,
  () => {
    if (
      network.value &&
      network.value.name !== CORRECT_CHAIN.adapterNetworkName &&
      !network.value.url.includes('okx')
    ) {
      if (!dialogRef?.visible) {
        dialogRef = dialog.open(InvalidNetworkDialog, {
          props: {
            modal: true,
            contentStyle: { width: '400px' },
            draggable: false,
            closable: false,
            header: 'Change Network to Aptos mainnet',
            showHeader: true,
          },
        });
      }
    } else {
      dialogRef.close();
    }
  },
  {
    deep: true,
  },
);

watch(smartRouterMode, async (newValue) => {
  if (newValue === 'default') {
    showBetaWarningToast();
  } else {
    removeBetaWarningToast();
  }
});

function checkLoadPools() {
  if (!mainStore.account.value?.address) {
    poolsStore.fetchDefaultPoolsData();
  }
}

function showBetaWarningToast() {
  toast.add({
    severity: 'info',
    summary: t('swap.beta.title'),
    detail: t('swap.beta.description'),
    group: 'tr',
  });
}

function removeBetaWarningToast() {
  toast.removeGroup('tr');
}

// TODO: add tab activating listener
// to rerequest actual state of wallet (account, balances, etc.)
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.app-container {
  max-width: 100vw;
  min-height: 100vh;
  background-image: url('./assets/background/bridge-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;
  animation: fade 1s;

  @media (min-width: 1400px) {
    background-size: 100%;
  }

  @media (max-width: 640px) {
    background-image: none;
  }

  &__loader {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &_lsd-staking {
    background: none;
  }
}

.app-container_not-found {
  max-width: 100vw;
  min-height: 100vh;
  background-image: url(@/assets/404-bg.png);
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #03031d;
  animation: fade 1s;

  &__loader {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .background-gradient {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 200vw;
    height: 200vh;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(252, 7, 117, 0.06) 0,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translate(-50vw, -100vh);
    z-index: -1;
  }

  @media screen and (max-width: 960px) {
    background-position: bottom;
    background-size: 100%;
  }
}

.tf-v1-popover-tooltip {
  color: black;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.farm-banner-wrapper {
  position: absolute;
  right: 5%;
  bottom: 10%;
}

//warning for beta-testers
.p-toast + .p-component.p-toast-top-right.p-ripple-disabled {
  border-radius: 16px;

  width: 28rem;
  z-index: 2;

  opacity: 1;

  @media (max-width: 480px) {
    width: 90%;
  }

  & .p-toast-message.p-toast-message-info {
    border-radius: 16px;
    border: 0 !important;
    background-color: #28253e !important;
  }

  & .p-toast-message-content {
    border-radius: 16px;

    gap: 24px;
    color: var(--text-color-secondary);
    font-size: 1rem;
    @media (max-width: 375px) {
      padding: 1rem;
      gap: 0.8rem;
    }

    .p-toast-message {
      color: #ffffff;
      font-size: 17px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.0043em;

      @media (max-width: 375px) {
        font-size: 1rem;
        margin: 0;
      }
    }
    .p-toast-detail {
      @media (max-width: 375px) {
        font-size: 1rem;
        margin-top: 0.3rem;
      }
    }
  }

  & .p-toast-icon-close {
    color: #d4c4ed;
    min-width: 2rem;
    @media (max-width: 375px) {
      min-width: 1.5rem;
    }
  }

  & .beta-icon {
    color: #ffcd29;
    font-size: 4rem !important;
    transform: rotate(180deg);
  }
}
</style>
