import { usePoolExistence } from './usePoolExistence';
import { getCurve, getResourcesAccount } from '@/utils/contracts';
import { useAptosClient } from './useAptosClient';
import { getPoolStr } from '@/utils/pools';
import { sortBy } from 'lodash';
import { TCurveType } from '@/types';

type TTrendingPoolResource = {
  tokenX: string;
  tokenY: string;
  curve: string;
  version: string;
  reserveX: number;
  reserveY: number;
  logoX?: string;
  logoY?: string;
  vol24?: number;
  sentioPairKey: string;
};

export interface GetMostLiquidityPoolOptions {
  tokenX: string;
  tokenY: string;
  poolCombinations: Array<{
    type: TCurveType;
    version: number;
  }>;
}

/**
 * Checks for the existence of pools and returns the pool with the largest reserves (liquidity) for a pair of tokens.
 * @returns
 */
export function useMostLiquidityPool() {
  const poolExistence = usePoolExistence();
  const aptos = useAptosClient();

  async function getMostLiquidityPool(options: GetMostLiquidityPoolOptions) {
    const { tokenX, tokenY, poolCombinations } = options;

    const poolCandidates = poolCombinations.map((poolCombination) => {
      const { type, version } = poolCombination;
      const curve = getCurve(type, version);

      return poolExistence.check(
        {
          fromCoin: tokenX,
          toCoin: tokenY,
          curve,
        },
        version,
      );
    });

    const resultPools = await Promise.all(poolCandidates);

    const existingPools = resultPools.filter(Boolean);

    const poolsResource = await Promise.all(
      existingPools.map((item) => {
        if (!item) return;
        const [tokenX, tokenY, curve, version] = item;
        const liquidityPool = getPoolStr(tokenX, tokenY, curve, +version);
        const resourceAccount = getResourcesAccount(+version);

        return aptos.client.getAccountResource(resourceAccount, liquidityPool);
      }),
    );

    const existingPoolsResourceData = existingPools.map((item, index) => {
      if (!item) return;

      const [tokenX, tokenY, curve, version] = item;
      const poolResource = poolsResource[index];
      return {
        tokenX,
        tokenY,
        curve,
        version,
        reserveX: +poolResource.data.coin_x_reserve.value,
        reserveY: +poolResource.data.coin_y_reserve.value,
      } as TTrendingPoolResource;
    });

    const sorted = sortBy(existingPoolsResourceData, ['reserveX', 'reserveY']);

    return sorted[sorted.length - 1];
  }

  return {
    getMostLiquidityPool,
  };
}
