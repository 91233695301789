import ApiController from "./index";
import {
  GetAddedLiquidityByAddress,
  GetApr,
  GetFarms,
  GetRegisteredCoins,
  GetRegisteredPools,
  GetTopVolumeAddress,
  GetTotalLiquidityByAddress,
  GetTotalSwaps,
  GetTotalSwapsByAddress,
  GetTradeRoute,
  GetTradingVolumeByAddress,
  GetTvl,
  GetUniqueUsersCount,
  GetVolume24,
  GetVolumes,
} from "../types";
import { API_URL } from "../types/urls";
import { GenericAbortSignal } from "axios";

export default class HttpController extends ApiController {
  /**
   * @Deprecated
   *
   * Retrieves the total number of swaps made by a specific address.
   *
   * @param {GetTotalSwapsByAddress.Query} query - The query parameters for the request.
   * @return {Promise<GetTotalSwapsByAddress.Response>} - A promise that resolves to the response containing the total number of swaps.
   */
  public async getTotalSwapsByAddress(query: GetTotalSwapsByAddress.Query) {
    try {
      return await this.get<GetTotalSwapsByAddress.Response>(
        API_URL.TOTAL_SWAPS_BY_ADDRESS,
        {
          params: query,
          responseType: "text",
        }
      );
    } catch (e) {
      console.log("getTotalSwapsByAddress error:", e);
    }
  }

  /**
   * @Deprecated
   *
   * Retrieves the total liquidity by address.
   *
   * @param {GetTotalLiquidityByAddress.Query} query - The query parameters for retrieving the total liquidity.
   * @return {Promise<GetTotalLiquidityByAddress.Response>} - A promise that resolves to the total liquidity response.
   */
  public async getTotalLiquidityByAddress(
    query: GetTotalLiquidityByAddress.Query
  ) {
    try {
      return await this.get<GetTotalLiquidityByAddress.Response>(
        API_URL.TOTAL_LIQUIDITY,
        {
          params: query,
        }
      );
    } catch (e) {
      console.log("getTotalLiquidityByAddress error:", e);
    }
  }

  /**
   * Retrieves the trading volume by address.
   *
   * @param {GetTradingVolumeByAddress.Query} query - The query parameters for retrieving the trading volume by address.
   * @return {Promise<GetTradingVolumeByAddress.Response>} - A promise that resolves to the trading volume response.
   */
  public async getTradingVolumeByAddress(
    query: GetTradingVolumeByAddress.Query
  ) {
    try {
      return await this.get<GetTradingVolumeByAddress.Response>(
        API_URL.TRADING_VOLUME_BY_ADDRESS,
        {
          params: query,
          responseType: "text",
        }
      );
    } catch (e) {
      console.log("getTradingVolumeByAddress error:", e);
    }
  }

  /**
   * Retrieves the added liquidity by address.
   *
   * @param {GetAddedLiquidityByAddress.Query} query - The query parameters for retrieving the added liquidity by address.
   * @return {Promise<GetAddedLiquidityByAddress.Response>} - A promise that resolves to the added liquidity response.
   */
  public async getAddedLiquidityByAddress(
    query: GetAddedLiquidityByAddress.Query
  ) {
    try {
      return await this.get<GetAddedLiquidityByAddress.Response>(
        API_URL.LIQUIDITY_BY_ADDRESS,
        {
          params: query,
        }
      );
    } catch (e) {
      console.log("getAddedLiquidityByAddress error:", e);
    }
  }

  /**
   * Retrieves the registered pools based on the provided query parameters.
   *
   * @param {GetRegisteredPools.Query} query - The query parameters for filtering the registered pools.
   * @return {Promise<GetRegisteredPools.Response>} - A promise that resolves to the response containing the registered pools.
   */
  public async getRegisteredPools(query: GetRegisteredPools.Query) {
    try {
      return await this.get<GetRegisteredPools.Response>(
        API_URL.POOLS_REGISTERED,
        {
          params: query,
        }
      );
    } catch (e) {
      console.log("getRegisteredPools error:", e);
    }
  }

  /**
   * Retrieves the top volume address based on the provided query parameters.
   *
   * @param {GetTopVolumeAddress.Query} query - The query parameters for filtering the top volume address.
   * @return {Promise<GetTopVolumeAddress.Response>} - A promise that resolves to the response containing the top volume address.
   */
  public async getTopVolumeAddress(query: GetTopVolumeAddress.Query) {
    try {
      return await this.get<GetTopVolumeAddress.Response>(
        API_URL.TOP_VOLUME_ADDRESS,
        {
          params: query,
        }
      );
    } catch (e) {
      console.log("getTopVolumeAddress error:", e);
    }
  }

  /**
   * Retrieves the registered coins based on the provided query parameters.
   *
   * @param {GetRegisteredCoins.Query} query - The query parameters for filtering the registered coins.
   * @return {Promise<GetRegisteredCoins.Response>} - A promise that resolves to the response containing the registered coins. If an error occurs, an empty array is returned.
   */
  public async getRegisteredCoins(query: GetRegisteredCoins.Query) {
    try {
      return await this.get<GetRegisteredCoins.Response>(
        API_URL.COINS_REGISTERED,
        {
          params: query,
        }
      );
    } catch (e) {
      console.log("getRegisteredCoins error:", e);
      return [];
    }
  }

  public async getVolume24(query: GetVolume24.Query) {
    try {
      return await this.get<GetVolume24.Response>(API_URL.VOLUME_24, {
        params: query,
      });
    } catch (error) {
      console.error("fetchVolume24: err", error);
    }
  }

  /**
   * Retrieves the total number of swaps made.
   *
   * @return {Promise<GetTotalSwaps.Response>} A promise that resolves to the response containing the total number of swaps.
   */
  public async getTvl() {
    try {
      return await this.get<GetTvl.Response>(API_URL.TVL);
    } catch (error) {
      console.error("getTvl error:", error);
    }
  }

  /**
   * @Deprecated
   *
   * Retrieves the total number of swaps made.
   *
   * @return {Promise<GetTotalSwaps.Response>} A promise that resolves to the response containing the total number of swaps.
   */
  public async getTotalSwaps() {
    try {
      return await this.get<GetTotalSwaps.Response>(API_URL.TOTAL_SWAPS);
    } catch (error) {
      console.error("getTotalSwaps error:", error);
    }
  }

  /**
   * @Deprecated
   *
   * Retrieves the unique number of users by making a GET request to the API_URL.USERS endpoint.
   *
   * @return {Promise<GetUniqueUsersCount.Response>} A promise that resolves to the response containing the unique number of users.
   */
  public async getUniqueUsers() {
    try {
      return await this.get<GetUniqueUsersCount.Response>(API_URL.USERS);
    } catch (error) {
      console.error("getUniqueUsers error:", error);
    }
  }

  /**
   * Retrieves the volumes based on the provided query parameters.
   *
   * @param {GetVolumes.Query} query - The query parameters for filtering the volumes.
   * @return {Promise<GetVolumes.Response>} A promise that resolves to the response containing the volumes.
   *                                        If an error occurs, it logs the error message to the console.
   */
  public async getVolumes(query: GetVolumes.Query) {
    try {
      return await this.get<GetVolumes.Response>(API_URL.VOLUMES, {
        params: query,
      });
    } catch (error) {
      console.error("getVolumes error:", error);
    }
  }

  /**
   * Retrieves the farms based on the provided query parameters.
   *
   * @param {GetFarms.Query} query - The query parameters for filtering the farms.
   * @return {Promise<GetFarms.Response>} - A promise that resolves to the response containing the farms. If an error occurs, it will be logged to the console.
   */
  public async getFarms(query: GetFarms.Query) {
    try {
      return await this.get<GetFarms.Response>(API_URL.FARMS, {
        params: query,
      });
    } catch (error) {
      console.error("getFarms error:", error);
    }
  }

  public async getRoute(
    query: GetTradeRoute.Query<"direct" | "default">,
    timeout?: number | undefined,
    signal?: GenericAbortSignal | undefined
  ) {
    try {
      return await this.get<GetTradeRoute.Response>(API_URL.SMART_ROUTER, {
        params: query,
        timeout,
        signal: signal,
      });
    } catch (error) {
      console.log("getRoute error:", error);
    }
  }
}
