import { MaybeRef } from '@vueuse/core';
import { ref, computed, isRef, unref, watchEffect, Ref, ToRefs } from 'vue';
import { useTokensStore } from '@/store';
import { Decimal } from 'decimal.js';
import { formatAliasForToken } from '@/utils/tokens';

const cutTrailingZerosFromString = (numberAsString: string) => {
  if (numberAsString.length === 1) return numberAsString;
  const arr = numberAsString.match(
    /^-?(((\d{1,3}),?)+\.*?)*?\d*?(?=\.?0*$)/,
  ) || [''];
  return arr[0];
};

export interface UseCurrencyFormat {
  alias: string;
  symbol: string;
  decimals: number;
  formatted: UseNumberFormat;
}

export type UseNumberFormat = string;

export function formatNumber(
  amount: undefined | Decimal.Value,
  decimals: number,
  options?: { suffix?: string; fullDecimals?: boolean; trimZero?: boolean },
) {
  const { fullDecimals = false, trimZero = true, suffix = '' } = options ?? {};

  let value = +(amount ?? 0) / Math.pow(10, decimals);
  let fixed = decimals;
  let prefix = '';

  if (!fullDecimals) {
    if (value > 10) {
      fixed = 2;
    } else if (value >= 1) {
      fixed = 4;
    } else if (value >= 0.1) {
      fixed = 6;
    }
  }

  if (value === 0) {
    fixed = 0;
  } else if (value < 1 / Math.pow(10, decimals)) {
    fixed = decimals;
    value = +Number(1 / Math.pow(10, decimals));
    prefix = '~';
  }

  fixed = fixed > decimals ? decimals : fixed;

  const formatter = Intl.NumberFormat('en', {
    notation: 'standard',
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  });

  return [
    prefix + trimZero
      ? cutTrailingZerosFromString(formatter.format(value))
      : formatter.format(value),
    suffix,
  ]
    .filter(Boolean)
    .join(' ');
}

export function useNumberFormat(
  amount: MaybeRef<string | number | undefined>,
  options: {
    decimals: MaybeRef<number>;
    suffix: MaybeRef<string | undefined>;
    fullDecimals?: MaybeRef<boolean>;
    trimZero?: MaybeRef<boolean>;
  } = { decimals: 1, suffix: '', fullDecimals: false, trimZero: true },
): Ref<UseNumberFormat> {
  const formatted = ref('');

  if (isRef(amount) || isRef(options.decimals) || isRef(options.suffix)) {
    watchEffect(() => {
      formatted.value = formatNumber(unref(amount), unref(options.decimals), {
        suffix: unref(options.suffix),
        fullDecimals: unref(options.fullDecimals),
        trimZero: unref(options.trimZero),
      });
    });
  } else {
    formatted.value = formatNumber(unref(amount), unref(options.decimals), {
      suffix: unref(options.suffix),
      fullDecimals: unref(options.fullDecimals),
      trimZero: unref(options.trimZero),
    });
  }

  return formatted;
}

export function useCurrencyFormat(
  amount: MaybeRef<string | number | undefined>,
  token: MaybeRef<string | undefined>,
  options?: {
    useSuffix?: boolean;
    _decimals?: number;
    trimZero?: boolean;
    fullDecimals?: boolean;
  },
): ToRefs<UseCurrencyFormat> {
  const tokensStore = useTokensStore();
  const {
    useSuffix = true,
    fullDecimals = false,
    trimZero = true,
    _decimals,
  } = options || {};

  const symbol = computed(() => {
    const tokenEntity = unref(token)
      ? tokensStore.getToken(unref(token) as string)
      : undefined;

    return tokenEntity ? tokenEntity.symbol : ''; // TODO refactor all
  });

  const alias = computed(() => {
    const tokenEntity = unref(token)
      ? tokensStore.getToken(unref(token) as string)
      : undefined;

    return tokenEntity ? formatAliasForToken(tokenEntity.alias) : symbol.value;
  });

  const decimals = computed(() => {
    const tokenEntity = unref(token)
      ? tokensStore.getToken(unref(token) as string)
      : undefined;
    return tokenEntity ? tokenEntity.decimals : _decimals ? _decimals : 8;
  });

  const formatted = useNumberFormat(amount, {
    decimals,
    suffix: useSuffix ? alias : '',
    fullDecimals,
    trimZero,
  });

  return {
    alias,
    symbol,
    decimals,
    formatted,
  };
}
