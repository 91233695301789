import { useCurrentAccountBalance } from '@/composables/useAccountBalance';
import { d } from '.';
import { getCurve, lpTokenNameStr } from './contracts';
import { TCurveType } from '@/types';
import { LP_DECIMALS } from '@/constants';

export function checkBalance(
  token?: string,
  balance?: number,
  amount?: number,
  defaultTokenValue?: string,
) {
  let internalBalance = d(balance);
  const internalAmount = d(amount);

  if (internalAmount.eq(0) || !token) {
    return false;
  }

  if (token === defaultTokenValue) {
    // Bind 500 tokens for gas
    internalBalance = internalBalance.minus(500);
  }

  return internalAmount.lte(internalBalance);
}

export async function hasLpBalance(
  from: string,
  to: string,
  curve: TCurveType,
  version: number,
) {
  const lpTokenName = lpTokenNameStr(
    {
      tokens: [from, to],
      curve: getCurve(curve, version),
    },
    version,
  );
  const { refetch } = useCurrentAccountBalance(lpTokenName, {
    useSuffix: false,
    _decimals: LP_DECIMALS,
  });

  const resource = await refetch();

  if (!resource) {
    return false;
  }

  const { balance, isExists } = resource;

  return isExists && d(balance).gt(0);
}
