import { ChainId } from '@/types';
import { NetworkName } from '@aptos-labs/wallet-adapter-core';

export enum ChainsEnum {
  'aptos-mainnet' = 'aptos-mainnet',
  'aptos-testnet' = 'aptos-testnet',
  'lumio-testnet' = 'lumio-testnet',
  'movement-testnet' = 'movement-testnet',
}

export const MAINNET_CHAIN_ID = 1;
export const APTOS_TESTNET_CHAIN_ID = 2;
export const L2_CHAIN_ID = 5;

export const CHAIN_MAP: Record<ChainsEnum, ChainId> = {
  [ChainsEnum['aptos-mainnet']]: MAINNET_CHAIN_ID,
  [ChainsEnum['aptos-testnet']]: APTOS_TESTNET_CHAIN_ID,
  [ChainsEnum['lumio-testnet']]: L2_CHAIN_ID,
  [ChainsEnum['movement-testnet']]: 0,
};

export const CORRECT_CHAIN_STRING: ChainsEnum =
  import.meta.env.VITE_CORRECT_CHAIN || ChainsEnum['aptos-mainnet'];

console.log('correct chain string', CORRECT_CHAIN_STRING);

export const BUILD_CHAIN_ID = CHAIN_MAP[CORRECT_CHAIN_STRING];

type TUrl = `http://${string}` | `https://${string}`;

export type TChain = {
  id: ChainsEnum;
  name: string;
  rpcUrl: TUrl;
  adapterNetworkName: NetworkName;
};

export const CHAINS_MAP: Record<ChainsEnum, TChain> = {
  [ChainsEnum['aptos-mainnet']]: {
    id: ChainsEnum['aptos-mainnet'],
    name: 'Aptos',
    rpcUrl: 'http://',
    adapterNetworkName: NetworkName.Mainnet,
  },
  [ChainsEnum['aptos-testnet']]: {
    id: ChainsEnum['aptos-testnet'],
    name: 'Aptos Testnet',
    rpcUrl: 'http://',
    adapterNetworkName: NetworkName.Testnet,
  },
  [ChainsEnum['lumio-testnet']]: {
    id: ChainsEnum['aptos-mainnet'],
    name: 'Lumio',
    rpcUrl: 'http://',
    adapterNetworkName: NetworkName.Testnet,
  },
  [ChainsEnum['movement-testnet']]: {
    id: ChainsEnum['aptos-mainnet'],
    name: 'Movement',
    rpcUrl: 'http://',
    adapterNetworkName: NetworkName.Testnet,
  },
};

export const CORRECT_CHAIN = CHAINS_MAP[CORRECT_CHAIN_STRING];
