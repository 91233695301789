import { createI18n } from 'vue-i18n';
import enUS from './enUS.json';

export type MessageSchema = typeof enUS;

export const i18nOptions = {
  locale: 'en-US',
  legacy: false,
  messages: { 'en-US': enUS },
};

const instance = createI18n<[MessageSchema], 'en-US'>(i18nOptions);
export default instance;
export const i18n = instance.global;
