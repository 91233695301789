import { MODULES_V1_ACCOUNT, REST_URL } from '@/constants';
import { composeType, is_sorted } from '@/utils/contracts';
import { AptosClient } from 'aptos';
import { NETWORKS_MODULES } from '@/constants/networks';
import { d } from '@/utils';

const aptosClient = new AptosClient(REST_URL);

export const fetchLiquidityByBinId = async (
  fromToken: string,
  toToken: string,
  binStep: number,
  binId: number,
) => {
  if (!fromToken || !toToken || !binStep || !binId) {
    return;
  }

  const functionName = 'get_bin_fields';

  const func_id = [NETWORKS_MODULES.LiquidityV1Pool, functionName].join('::');

  const binStepType = composeType(
    MODULES_V1_ACCOUNT,
    'bin_steps',
    `X${binStep}`,
  );

  const isSorted = is_sorted(fromToken, toToken);

  const payload = {
    function: func_id,
    type_arguments: [
      isSorted ? fromToken : toToken,
      isSorted ? toToken : fromToken,
      binStepType,
    ],
    arguments: [binId],
  };

  try {
    return await aptosClient.view(payload);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Function is realized here while the corresponding contract method has no #[view] modifier
 * @see https://github.com/pontem-network/liquidswap_v1/blob/main/sources/libs/bin_helper.move#L82
 */
export const getAmountsOutFromShares = (
  binReservesX: number,
  binReservesY: number,
  shares: number,
  totalSharesSuply: number,
) => {
  const amountXOut =
    binReservesX > 0 ? +d(shares).mul(binReservesX).div(totalSharesSuply) : 0;

  const amountYOut =
    binReservesY > 0 ? +d(shares).mul(binReservesY).div(totalSharesSuply) : 0;

  return [amountXOut, amountYOut];
};

export const getIsAccountCanReceiveDirectCoinTransfers = (address: string) => {
  const functionName = 'can_receive_direct_coin_transfers';

  const payload = {
    function: ['0x1', 'aptos_account', functionName].join('::'),
    type_arguments: [],
    arguments: [address],
  };

  return aptosClient.view(payload);
};
