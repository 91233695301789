import { d, decimalsMultiplier } from '@/utils';
import { useTokensStore } from '@/store';
import { VERSION_0_5 } from '@/constants';
import { getCurve } from '@/utils/contracts';
import { IStoredToken } from '@/types';
import { GetTradeRoute } from 'ls-api';
import { MaybeRef } from '@vueuse/core';
import { isRef, toValue } from 'vue';
import { useWalletPlugin } from '@/composables/useWalletPlugin';

export type ResponsePath = Extract<
  keyof GetTradeRoute.Response,
  'defaultMode' | 'directMode'
>;

const MULTIPLY = 10000;
const cachedResponseWeakMap = new WeakMap<
  GetTradeRoute.Query<'direct' | 'default'>,
  GetTradeRoute.Response
>();
const cachedResponseMap = new Map<string, GetTradeRoute.Response>();

export const checkIsQueryFull = (
  query?: Partial<GetTradeRoute.Query<'default' | 'direct'>>,
) => {
  if (!query) {
    return false;
  }
  const parsed = JSON.parse(JSON.stringify(query));
  return Object.values(parsed).every(
    (value) => value !== 'undefined' || !!value,
  );
};

export const useSwapCalculations = (
  cacheType: 'weak' | 'normal' = 'normal',
) => {
  const tokensStore = useTokensStore();
  const cachedResponse =
    cacheType === 'weak' ? cachedResponseWeakMap : cachedResponseMap;

  function convertToDecimals(
    amt?: number,
    from?: IStoredToken,
    to?: IStoredToken,
  ) {
    if (
      !amt ||
      !from?.amount ||
      !to?.amount ||
      !from?.token ||
      !to?.token ||
      !from?.decimals ||
      !to?.decimals
    ) {
      return amt;
    }

    const diff = to.decimals - from.decimals;
    if (diff > 0) {
      return +d(amt).div(decimalsMultiplier(diff)).toFixed(0);
    } else if (diff < 0) {
      return +d(amt).mul(decimalsMultiplier(diff)).toFixed(0);
    }

    return amt;
  }

  function convertFromDecimals(
    amt?: number,
    fromToken?: string,
    toToken?: string,
  ) {
    if (!amt || !fromToken || !toToken) {
      return amt;
    }
    const fromDecimals = tokensStore.tokens[fromToken].decimals;
    const toDecimals = tokensStore.tokens[toToken].decimals;

    const diff = fromDecimals - toDecimals;
    if (diff > 0) {
      return +d(amt).div(decimalsMultiplier(diff)).toFixed(0);
    } else if (diff < 0) {
      return +d(amt).mul(decimalsMultiplier(diff)).toFixed(0);
    }

    return amt;
  }

  function getSlippageAmount({
    amount,
    slippage,
    mode,
  }: {
    amount: string | number | undefined;
    slippage: number;
    mode: 'from' | 'to';
  }) {
    if (
      !amount ||
      !slippage ||
      Number.isNaN(Number(amount)) ||
      Number.isNaN(slippage)
    ) {
      return amount?.toString() || '0';
    }

    const slippagePercent = +slippage * MULTIPLY;
    let result = 0;

    if (mode === 'from') {
      result = Number(amount) - (Number(amount) * slippagePercent) / MULTIPLY;
    } else if (mode === 'to') {
      result = Number(amount) + (Number(amount) * slippagePercent) / MULTIPLY;
    }

    return Math.floor(result).toString();
  }

  function calculateDefaultModeSlippage({
    mode,
    to,
    from,
    slippage,
  }: {
    mode: 'from' | 'to';
    to: MaybeRef<IStoredToken>;
    from: MaybeRef<IStoredToken>;
    slippage?: MaybeRef<number>;
  }): number {
    const _toAmount = isRef(to) ? to.value.amount : to.amount;
    const _fromAmount = isRef(from) ? from.value.amount : from.amount;
    const _amount = mode === 'from' ? _toAmount : _fromAmount;
    const _slippage = isRef(slippage) ? slippage.value : slippage;
    if (_amount === undefined || _slippage === undefined || _amount === 0) {
      return 0;
    }
    return +getSlippageAmount({
      amount: _amount,
      slippage: _slippage,
      mode: mode,
    });
  }

  function calculateDirectModeSlippage({
    mode,
    to,
    from,
    slippage,
    curve,
    version,
  }: {
    mode: 'from' | 'to';
    to: MaybeRef<IStoredToken>;
    from: MaybeRef<IStoredToken>;
    slippage?: MaybeRef<number>;
    curve?: MaybeRef<string | undefined>;
    version?: MaybeRef<0 | 1 | 0.5 | undefined>;
  }): string {
    const _slippage = isRef(slippage) ? toValue(slippage) : slippage;
    const _curve = isRef(curve) ? toValue(curve) : curve;
    const _version = isRef(version) ? toValue(version) : version;
    const _toAmount = isRef(to) ? toValue(to).amount : to.amount;
    const _fromAmount = isRef(from) ? toValue(from).amount : from.amount;

    if (_slippage === undefined) {
      return '0';
    }
    const slippagePercent = _slippage * MULTIPLY;
    if (mode === 'from' && _toAmount !== undefined) {
      if (
        _version === VERSION_0_5 ||
        _curve === getCurve('unstable', _version)
      ) {
        return (_toAmount - (_toAmount * slippagePercent) / MULTIPLY).toFixed(
          0,
        );
      }
      return (_toAmount - 1).toFixed(0);
    } else if (mode === 'to' && _fromAmount !== undefined) {
      return (_fromAmount + (_fromAmount * slippagePercent) / MULTIPLY).toFixed(
        0,
      );
    }
    return '0';
  }

  function calculateDefaultModePriceImpact({
    fromUsd,
    toUsd,
    fromAmount,
    toAmount,
  }: {
    fromUsd?: number;
    toUsd?: number;
    fromAmount?: number;
    toAmount?: number;
  }) {
    if (!fromUsd || !toUsd || !fromAmount || !toAmount) {
      return 0;
    }
    return d(fromUsd).div(d(toUsd)).minus(1).mul(100).mul(-1).toNumber();
  }

  function calculateDirectModePriceImpact({
    fromAmount,
    fromReserve,
    toReserve,
  }: {
    fromAmount?: number;
    fromReserve?: number;
    toReserve?: number;
  }) {
    if (!fromAmount || !fromReserve || !toReserve) {
      return 0;
    }

    const constantProduct = fromReserve * toReserve;
    const reserveToAfter = constantProduct / (fromReserve + fromAmount);
    const amountOut = toReserve - reserveToAfter;
    const marketPrice = fromAmount / amountOut;
    const midPrice = fromReserve / toReserve;
    return (1 - midPrice / marketPrice) * 100;
  }

  const priceImpactFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function saveResponseToCache(
    response: GetTradeRoute.Response,
    query: GetTradeRoute.Query<'default' | 'direct'>,
  ) {
    const isValidResponse = checkIsValidResponse(query.mode, response);
    if (isValidResponse) {
      const cacheKey = cacheType === 'weak' ? query : JSON.stringify(query);
      cachedResponse.set(
        cacheKey as GetTradeRoute.Query<'direct' | 'default'> & string,
        response,
      );
      return true;
    }
    return false;
  }

  function getResponseFromCache(
    query: GetTradeRoute.Query<'default' | 'direct'>,
  ) {
    const cacheKey = cacheType === 'weak' ? query : JSON.stringify(query);
    return cachedResponse.get(
      cacheKey as GetTradeRoute.Query<'direct' | 'default'> & string,
    );
  }

  function clearCache() {
    (cachedResponse as typeof cachedResponseMap)?.clear();
  }

  function checkIsValidResponse(
    smartRouterMode?: MaybeRef<GetTradeRoute.SmartRouterMode>,
    response?: GetTradeRoute.Response,
  ) {
    if (!smartRouterMode || !response) {
      return false;
    }
    const _mode = toValue(smartRouterMode);
    const responsePath: ResponsePath =
      _mode === 'default' ? 'defaultMode' : 'directMode';
    const responseByMode = response && response[responsePath];
    return (
      response &&
      responseByMode &&
      responseByMode.path &&
      Array.isArray(responseByMode.path) &&
      responseByMode.path.length > 0
    );
  }

  function shouldSwitchToDefaultMode(
    response: GetTradeRoute.Response,
    interactionField: 'from' | 'to',
  ) {
    if (
      !response ||
      !interactionField ||
      ['from', 'to'].indexOf(interactionField) === -1
    ) {
      return false;
    }
    const { account } = useWalletPlugin();

    const isUserConnected = Boolean(account?.value?.address);
    const isValidDefaultResponse = checkIsValidResponse('default', response);
    const isValidDirectResponse = checkIsValidResponse('direct', response);
    if (!isUserConnected && isValidDefaultResponse && isValidDirectResponse) {
      const lastItemDefault = response.defaultMode.path.slice(-1)[0];
      const lastItemDirect = response.directMode.path.slice(-1)[0];
      return interactionField === 'from'
        ? +lastItemDefault.outputAmount > +lastItemDirect.outputAmount
        : +lastItemDefault.inputAmount < +lastItemDirect.inputAmount;
    }
    return false;
  }

  return {
    convertToDecimals,
    convertFromDecimals,
    calculateDefaultModeSlippage,
    calculateDirectModeSlippage,
    calculateDefaultModePriceImpact,
    calculateDirectModePriceImpact,
    priceImpactFormatter,
    saveResponseToCache,
    getResponseFromCache,
    clearCache,
    checkIsQueryFull,
    checkIsValidResponse,
    shouldSwitchToDefaultMode,
  };
};
