import { APTOS, REST_URL } from '@/constants';
import {
  APTOS_DEFAULT_TOKENS_MAPPING,
  NETWORKS_MODULES,
  TDefaultToken,
} from '@/constants/networks';

// TODO: while different networks will have different modules address
// modify these functions

export type TModuleType =
  | 'Scripts'
  | 'Staking'
  | 'Faucet'
  | 'FaucetL2'
  | 'LiquidityPool'
  | 'CoinInfo'
  | 'CoinStore';

/**
 * Returns module address for passed TModuleType
 *
 * @param name TModuleType
 * @returns Address of module
 */
export function moduleAddress(name: TModuleType): string {
  return NETWORKS_MODULES[name];
}

/**
 * Returns token address for passed TDefaultToken
 *
 * @param type TDefaultToken
 * @returns type string for token
 */
export function tokenAddress(type: TDefaultToken): string {
  return APTOS_DEFAULT_TOKENS_MAPPING[type];
}

/**
 * Returns token address for network default token
 *
 * @returns type address for default network token
 */
export function defaultToken() {
  return APTOS;
}

/**
 * Returns rest url for given chain id
 *
 * @param chainId string with chain id
 * @returns REST URL
 */
export function restUrl(chainId: string) {
  const URLS: Record<string, string> = {
    '1': 'https://api.mainnet.aptoslabs.com/v1',
    '2': 'https://api.testnet.aptoslabs.com/v1',
    // 4: 'https://testnet-node.devops.mom/v1',
    '5': REST_URL,
    '33': 'https://fullnode.devnet.aptoslabs.com/v1',
  };
  if (!Object.keys(URLS).includes(chainId)) {
    throw new Error('Unknown network id');
  }
  return URLS[chainId];
}
