import NProgress from 'nprogress';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { GEO_FENCE_BLOCKED_KEY } from '@/constants';

NProgress.configure({ showSpinner: false });

const children: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('../views/SwapPage.vue'),
    name: 'swap',
  },
  {
    path: '/pools',
    component: () => import('../views/PoolsPage.vue'),
    name: 'pools',
  },
  {
    path: '/pools/v1',
    component: () => import('../views/PoolsV1Page.vue'),
    name: 'poolsV1',
  },
  {
    path: '/stats',
    component: () => import('../views/StatsPage.vue'),
    name: 'stats',
  },
  {
    path: '/dashboard',
    component: () => import('../views/DashboardPage.vue'),
    name: 'dashboard',
  },
  {
    path: '/dashboard-preview',
    component: () => import('../views/DashboardPreviewPage.vue'),
    name: 'dashboard-preview',
  },
  {
    path: '/token-generation',
    component: () => import('../views/TokenGenerationPage.vue'),
    name: 'token-generation',
  },
  {
    path: '/terms',
    component: () => import('../views/TermsPage.vue'),
    name: 'terms',
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: () => import('../views/404Page.vue'),
  },
  {
    path: '/error/403',
    name: 'forbidden',
    component: () => import('../views/403Page.vue'),
  },
  {
    path: '/lsd-staking',
    name: 'lsd-staking',
    component: () => import('../views/LsdStaking.vue'),
  },
];

children.push(
  {
    path: '/aptos-staking/:view?',
    component: () => import('../views/AptosStakingPage.vue'),
    props: (route: any) => ({
      view: route.params.view,
      page: route.query.page,
    }),
    name: 'aptos-staking',
  },
  {
    path: '/aptos-staking/:pool/:action(stake|unstake|restake|info)',
    component: () => import('../views/AptosStakingActionPage.vue'),
    name: 'aptos-staking-action',
    props: (route: any) => ({
      pool: route.params.pool,
      action: route.params.action,
    }),
  },
);

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/layouts/AppLayout.vue'),
    children,
  },
  {
    path: '/widget/:address?/:networkName?/:chainId?/:status?/:hash?',
    component: () => import('../views/WidgetPage.vue'),
    props: (route: any) => ({
      address: route.params.address,
      networkName: route.params.networkName,
      chainId: route.params.chainId,
      status: route.params?.status,
      hash: route.params?.hash,
    }),
    name: 'widget',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let alreadyLoaded = true;

router.beforeEach(function (to, from, next) {
  alreadyLoaded = false;
  if (
    +window.$cookies.get(GEO_FENCE_BLOCKED_KEY) &&
    !to.path.includes('/error/403')
  ) {
    next({ path: '/error/403' });
    return;
  }
  // hide loader bar for widget page
  if (to?.name === 'widget') {
    next();
    return;
  }
  // A hack that doesn't show the loading loader if the component has already been loaded into memory.
  setTimeout(() => {
    if (!alreadyLoaded) {
      NProgress.start();
      NProgress.set(0.1);
    }
  }, 10);
  if (to.path === '/' && to.hash.startsWith('#/')) {
    next(to.hash.substring(1));
  } else {
    next();
  }
});

router.afterEach(() => {
  alreadyLoaded = true;
  setTimeout(() => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
  }, 200);
});

export default router;
