import { LIQUIDSWAP_API_URL } from '@/constants';
import { getContractVersionFromCurve, getCurve } from '@/utils/contracts';
import apolloClient from '@/plugins/apollo';
import GetAccountNftListByCreatorAccount from '@/graphql/getAccountNftListByCreatorAccount';
import GetAccountNftList from '@/graphql/getAccountNftList';
import { captureException } from '@sentry/vue';
import { OperationVariables, QueryOptions } from '@apollo/client/core';

export const fetchVolume24 = async () => {
  try {
    const response = await fetch(`${LIQUIDSWAP_API_URL}/sentio/volume24`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('fetchVolume24: err', error);
  }
};

export const fetchPooledFunds = async (
  address: string,
  x: string,
  y: string,
  curve: string,
  version?: number,
) => {
  if (
    ![
      'stable',
      'unstable',
      getCurve('stable', version),
      getCurve('unstable', version),
    ].includes(curve)
  ) {
    throw new Error(`Wrong pool curve type`);
  }

  let contractVersion: number | undefined = undefined;
  try {
    contractVersion = getContractVersionFromCurve(curve);
  } catch (error) {
    contractVersion = undefined;
  }

  if (version === undefined && contractVersion === undefined) {
    throw new Error(`fetchPooledFunds: pool version is not provided`);
  }

  try {
    const url = `${LIQUIDSWAP_API_URL}/sentio/liq?address=${address}&x=${x}&y=${y}&curve=${curve}&version=${version}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch pooled funds error', error);
  }
};

export const fetchAllPooledFundsByAddress = async (address: string) => {
  try {
    const url = `${LIQUIDSWAP_API_URL}/sentio/total_liq?address=${address}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      'fetchAllPooledFundsByAddress: Fetch all pooled funds error',
      error,
    );
  }
};

export async function fetchTvl() {
  try {
    const response = await fetch(`${LIQUIDSWAP_API_URL}/sentio/tvl`);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
}

export async function fetchCLLiquidity(
  userAddress: string,
  nftCreatorAddress: string,
  collectionName: string,
) {
  const data = await GQLPaginator(
    {
      query: GetAccountNftListByCreatorAccount,
      variables: {
        address: userAddress,
        creatorAddress: nftCreatorAddress,
        collectionName,
      },
      fetchPolicy: 'network-only',
    },
    'current_token_ownerships',
  );

  const userLiquidity = data.map((item: any) => ({
    name: item.current_token_data.name,
    amount: item.amount,
    supply: item.current_token_data.supply,
  }));

  return userLiquidity;
}

export async function fetchAllAccountNfts(userAddress: string) {
  const data = await GQLPaginator(
    {
      query: GetAccountNftList,
      variables: {
        address: userAddress,
      },
    },
    'current_token_ownerships',
  );

  const userNFTs = data.map((item: any) => {
    return {
      key: item.token_data_id_hash,
      collectionName:
        item.current_collection_data?.collection_name || item.collection_name,
      creator: item.current_token_data.creator_address,
      collection:
        item.current_collection_data?.collection_name || item.collection_name,
      description: item.current_token_data.description,
      name: item.current_token_data.name,
      maximum: item.current_token_data.maximum,
      supply: item.current_token_data.supply,
      uri: item.current_token_data.metadata_uri,
      largest_property_version: item.property_version,
    };
  });

  return userNFTs;
}

/**
 * The function ensures that all data is retrieved from the indexer, starting from 0, ending with the last record on request.
 * Accepts 2 arguments - an GQL query and a targetField that searches for specific data.
 * @param options
 * @param targetField
 * @returns
 */
async function GQLPaginator<
  T = any,
  TVariables extends OperationVariables = OperationVariables,
>(options: QueryOptions<TVariables, T>, targetField: string) {
  const { offset, limit } = options.variables;

  const LIMIT = limit || 100;
  const OFFSET_DIFF = offset || 100;

  let currentOffset = 0;

  const data = [];

  // eslint-disable-next-line
  while (true) {
    try {
      options.variables = {
        ...options.variables,
        limit: LIMIT,
        offset: currentOffset,
      };

      const response = await apolloClient.query(options);
      const targetData = (response as any)?.data[targetField];

      if (!targetData || (targetData && !targetData.length)) break;

      data.push(...targetData);
      currentOffset += OFFSET_DIFF;
    } catch (err) {
      console.error(
        `GQLPaginator (limit: ${LIMIT}, offset: ${OFFSET_DIFF})`,
        err,
      );
      captureException(err);
      break;
    }
  }

  return data;
}
