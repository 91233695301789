import { ref } from 'vue';
import { VALID_VERSIONS, VERSION_0, VERSION_0_5, VERSION_1 } from '@/constants';

export type Version = typeof VALID_VERSIONS[number];

export type LocalContractVersion =
  | `${typeof VERSION_0}`
  | `${typeof VERSION_0_5}`
  | `${typeof VERSION_1}`;

export type ContractVersion = '0' | '5' | '10';

export function useContractVersion(defaultVersion: Version = VERSION_0) {
  const version = ref(defaultVersion);

  /**
   * Set contract version
   * @param newVersion
   */
  function setVersion(newVersion: Version) {
    if (!VALID_VERSIONS.includes(newVersion)) {
      throw new Error('Trying to switch on wrong swap contract version');
    }
    version.value = newVersion;
  }

  /**
   * Get contract version by local version for smart router contract only
   * @param version
   */
  function getContractVersion(version: LocalContractVersion): ContractVersion {
    const versions: Record<LocalContractVersion, ContractVersion> = {
      '0': '0',
      '0.5': '5',
      '1': '10',
    };
    return versions[version];
  }

  return {
    version,
    setVersion,
    getContractVersion,
  };
}
