import { MODULES_V1_ACCOUNT, STAKING_ACCOUNT } from './../constants/index';
import { getCurve } from '@/utils/contracts';
import { useTokensStore } from '@/store';
import {
  composeType,
  getModulesAccount,
  getResourcesAccount,
  is_sorted,
} from './contracts';
import { moduleAddress } from './networkData';
import { formatAliasForToken } from './tokens';

export function getPoolStr(
  coinX: string,
  coinY: string,
  curve: string,
  contract?: number,
): string {
  const [sortedX, sortedY] = is_sorted(coinX, coinY)
    ? [coinX, coinY]
    : [coinY, coinX];
  const moduleAccount = getModulesAccount(contract);
  return composeType(
    composeType(moduleAccount, 'liquidity_pool', 'LiquidityPool'),
    [sortedX, sortedY, curve],
  );
}

export function getPoolV1Str(
  coinX: string,
  coinY: string,
  binStep: number,
): string {
  const [sortedX, sortedY] = is_sorted(coinX, coinY)
    ? [coinX, coinY]
    : [coinY, coinX];
  const v1Type = composeType(MODULES_V1_ACCOUNT, 'bin_steps', `X${binStep}`);
  return composeType(composeType(MODULES_V1_ACCOUNT, 'pool', 'Pool'), [
    sortedX,
    sortedY,
    v1Type,
  ]);
}

export function shortCurveStr(curve: string, contract?: number) {
  if (['unstable', 'stable'].includes(curve.toLowerCase())) {
    return curve;
  }
  return curve === getCurve('stable', contract) ? 'Stable' : 'Uncorrelated';
}

export function getPoolLpStr(
  coinX: string,
  coinY: string,
  curve: string,
  contract?: number,
): string {
  const [sortedX, sortedY] = is_sorted(coinX, coinY)
    ? [coinX, coinY]
    : [coinY, coinX];

  /**
   * When pool versions were exclusively 0.
   * We stored their curves as 'stable' and 'unstable'.
   * Then we switched to the format of storing the curve in its full form (address, description, type).
   * This condition guarantees that the curve will be of the full type.
   */
  if (['stable', 'unstable'].includes(curve)) {
    // TODO: refactor after tests
    curve =
      curve === 'stable'
        ? getCurve('stable', contract)
        : getCurve('unstable', contract);
  }
  const resourceAccount = getResourcesAccount(contract);
  return composeType(resourceAccount, 'lp_coin', 'LP', [
    sortedX,
    sortedY,
    curve,
  ]);
}

export function getStakingPoolStr(lp: string, reward: string) {
  return composeType(
    //
    STAKING_ACCOUNT,
    'stake',
    'StakePool',
    [lp, reward],
  );
}

export function destructCoinStorePoolStr(type: string): string[] {
  if (type.length === 0) return [];
  return (
    type
      //
      .split('<')[2]
      .split('>')[0]
      .replaceAll(' ', '')
      .split(',')
  );
}

export function destructCoinStorePoolV1Str(type: string): string[] {
  if (type.length === 0) return [];
  return (
    type
      //
      .split('<')[1]
      .split('>')[0]
      .replaceAll(' ', '')
      .split(',')
  );
}

export function getPoolLpInfoStr(lp: string): string {
  return composeType(moduleAddress('CoinInfo'), [lp]);
}

export function getTitleForPool(coinX: string, coinY: string) {
  if (!coinX || !coinY) return '';
  const tokensStore = useTokensStore();
  const [sortedX, sortedY] = is_sorted(coinX, coinY)
    ? [coinX, coinY]
    : [coinY, coinX];
  const tokenX = tokensStore.getToken(sortedX);
  const tokenY = tokensStore.getToken(sortedY);
  if (!tokenX || !tokenY) return '';
  const { alias: symbolX } = tokenX;
  const { alias: symbolY } = tokenY;
  const formattedSymbolX = formatAliasForToken(symbolX);
  const formattedSymbolY = formatAliasForToken(symbolY);

  return `${formattedSymbolX}/${formattedSymbolY}`;
}
