import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';

import { RESOURCES_V1_ACCOUNT, VERSION_1 } from '@/constants';
import { getFromCache } from '@/utils/cache';
import { useAptosClient } from '@/composables/useAptosClient';
import { IPersistedPoolV1 } from '@/types/pools';
import { destructCoinStorePoolV1Str } from '@/utils/pools';
import { getBinStepFromType, is_sorted } from '@/utils/contracts';
import { useStore } from '@/store/useStore';
import { useTokensStore } from '@/store/useTokensStore';
import { useNumberFormat } from '@/composables/useCurrencyFormat';
import { getTokenPrice, getUSDEquivalent } from '@/composables/useCoinPrice';
import { useFetchBinAmounts } from '@/composables/liquidity/useFetchBinAmounts';
import { fetchCLLiquidity } from '@/service/api';

export const usePoolsV1Store = defineStore('usePoolStoreV1', () => {
  const aptos = useAptosClient();
  const poolsMap = reactive<Record<string, IPersistedPoolV1>>({});
  const { networkId } = useStore();
  const tokensStore = useTokensStore();
  const store = useStore();
  const { fetchBinAmounts } = useFetchBinAmounts();
  const poolsViewTab = ref('pools');
  const isLoading = ref(false);

  const poolsMapAsArray = computed(() => Object.values(poolsMap));

  const fetchPools = async () => {
    try {
      isLoading.value = true;
      const resourcesResponse = await getFromCache(
        ['calc', RESOURCES_V1_ACCOUNT, 'pools'].join('-'),
        () => aptos.client.getAccountResources(RESOURCES_V1_ACCOUNT),
        { time: 2000 },
      );

      for (const element of resourcesResponse) {
        if (element.type.indexOf('pool::Pool') === -1) continue;

        //FIXME: there are created two equal pools with different collection names on testnet
        if (
          element.data.collection_name === 'Liquidswap v1 #1 "BTC"-"USDT"-"X10"'
        )
          continue;

        const [coinX, coinY, binStepType] = destructCoinStorePoolV1Str(
          element.type,
        );

        const binStep = getBinStepFromType(binStepType);

        const isSorted = is_sorted(coinX, coinY);
        const [sortedX, sortedY] = isSorted ? [coinX, coinY] : [coinY, coinX];

        const poolStr = element.data.collection_name;

        const persistedPool: IPersistedPoolV1 = reactive({
          collection_name: element.data.collection_name,
          coinX: sortedX,
          coinY: sortedY,
          id: `${sortedX}-${sortedY}-${binStepType}`,
          binStepType,
          binStep,
          active_bin_id: element.data.active_bin_id,
          active_bin_price: 1,
          reserveX: isSorted
            ? element.data.coins_x?.value || 0
            : element.data.coins_y?.value || 0,
          reserveY: isSorted
            ? element.data.coins_y?.value || 0
            : element.data.coins_x?.value || 0,
          addedX: undefined,
          addedY: undefined,
          lp: 0,
          networkId: networkId.value,
          contract: VERSION_1,
        });

        poolsMap[poolStr] = persistedPool;

        await Promise.all([fetchTvl(poolStr), getAddedXAndYAmounts(poolStr)]);
      }
    } catch (e) {
      console.error('usePoolsStore: fetchPools', e);
    } finally {
      isLoading.value = false;
    }

    return new Promise((resolve) => resolve(true));
  };

  async function fetchTvl(liquidityPool: string) {
    const pool: IPersistedPoolV1 = poolsMap[liquidityPool];

    const tokenX = tokensStore.getToken(pool.coinX);
    const tokenY = tokensStore.getToken(pool.coinY);
    const symbolX = tokenX?.symbol;
    const symbolY = tokenY?.symbol;
    const decimalX = tokenX?.decimals ? tokenX.decimals : 0;
    const decimalY = tokenY?.decimals ? tokenY.decimals : 0;

    const reservesX = useNumberFormat(pool.reserveX, {
      decimals: decimalX,
      suffix: '',
    }).value.replaceAll(',', '');

    const reservesY = useNumberFormat(pool.reserveY, {
      decimals: decimalY,
      suffix: '',
    }).value.replaceAll(',', '');

    try {
      const currencyXPrice = await getTokenPrice(symbolX);
      const currencyYPrice = await getTokenPrice(symbolY);

      if (!currencyXPrice || !currencyYPrice) return;

      const usdX = getUSDEquivalent(+reservesX, currencyXPrice);
      const usdY = getUSDEquivalent(+reservesY, currencyYPrice);
      if (!usdX && !usdY) return;

      const totalUsd = +(usdX || 0) + +(usdY || 0);
      pool.tvl = totalUsd;
    } catch (error) {
      console.error('fetchTvl', error);
    }
  }

  async function getAddedXAndYAmounts(collectionName: string) {
    try {
      const pool: IPersistedPoolV1 = poolsMap[collectionName];

      if (!pool) {
        throw new Error('There is no pool in pools map');
      }

      const address = store.account.value?.address;

      const tokenX = tokensStore.getToken(pool.coinX);
      const tokenY = tokensStore.getToken(pool.coinY);
      const decimalX = tokenX?.decimals ? tokenX.decimals : 0;
      const decimalY = tokenY?.decimals ? tokenY.decimals : 0;

      const binStep = pool.binStep;

      if (!address || !pool.coinX || !pool.coinY || !binStep) {
        return;
      }

      const userBinsWithLiquidity = await fetchCLLiquidity(
        address,
        RESOURCES_V1_ACCOUNT,
        collectionName,
      );
      userBinsWithLiquidity.sort((a: any, b: any) => a.name - b.name);

      const decimalsFrom = decimalX ?? 0;
      const decimalsTo = decimalY ?? 0;

      const [decimalsX, decimalsY] = is_sorted(pool.coinX, pool.coinY)
        ? [decimalsFrom, decimalsTo]
        : [decimalsTo, decimalsFrom];

      const binAmounts = await fetchBinAmounts(
        pool.coinX,
        pool.coinY,
        binStep,
        userBinsWithLiquidity,
        decimalsX,
        decimalsY,
      );

      const xTokensAmount = computed(() => {
        return binAmounts.reduce((acc, current) => {
          const curr = current?.amountXOut ?? 0;
          return acc + curr;
        }, 0);
      });

      const yTokensAmount = computed(() => {
        return binAmounts.reduce((acc, current) => {
          const curr = current?.amountYOut ?? 0;
          return acc + curr;
        }, 0);
      });

      poolsMap[collectionName].addedX = xTokensAmount.value;
      poolsMap[collectionName].addedY = yTokensAmount.value;

      const currencyXPrice = await getTokenPrice(tokenX?.symbol);
      const currencyYPrice = await getTokenPrice(tokenY?.symbol);

      const usdX = computed(() =>
        getUSDEquivalent(+xTokensAmount.value, currencyXPrice),
      );
      const usdY = computed(() =>
        getUSDEquivalent(+yTokensAmount.value, currencyYPrice),
      );

      const activeBinId = pool.active_bin_id;

      const inRange = binAmounts.some(
        (item) => item.name === activeBinId.toString(),
      );

      poolsMap[collectionName].inRange = inRange;

      const totalUsd = +(usdX.value || 0) + +(usdY.value || 0);
      poolsMap[collectionName].totalUsd = totalUsd;
    } catch (e) {
      console.error('getAddedXandYAmounts', e);
    }
  }

  const resetAddedFunds = (collectionName: string) => {
    const pool = poolsMap[collectionName];
    if (!pool) {
      throw new Error('resetAddedFunds: there is no pool in pool`s map');
    }

    pool.addedX = undefined;
    pool.addedY = undefined;
  };

  const resetAddedFundsForAllPools = () => {
    Object.keys(poolsMap).forEach((poolKey) => {
      const pool = poolsMap[poolKey];
      if (!pool) return;

      pool.addedX = 0;
      pool.addedY = 0;
    });
  };

  function getPoolByKey(key: string) {
    return poolsMap[key];
  }

  function getPoolBySignature(coinX: string, coinY: string, binStep: number) {
    const [sortedX, sortedY] = is_sorted(coinX, coinY)
      ? [coinX, coinY]
      : [coinY, coinX];

    const pools = Object.keys(poolsMap).map((poolStr) => poolsMap[poolStr]);
    return pools.find((pool) => {
      return (
        pool.coinX === sortedX &&
        pool.coinY === sortedY &&
        pool.binStep === binStep
      );
    });
  }

  const poolsByTokenSymbol = computed(() => (coinX: string, coinY: string) => {
    const [sortedX, sortedY] = is_sorted(coinX, coinY)
      ? [coinX, coinY]
      : [coinY, coinX];
    return poolsMapAsArray.value?.filter(
      (pool) => pool.coinX === sortedX && pool.coinY === sortedY,
    );
  });

  return {
    fetchPools,
    getPoolByKey,
    getPoolBySignature,
    getAddedXAndYAmounts,
    resetAddedFunds,
    resetAddedFundsForAllPools,
    poolsMap,
    poolsMapAsArray,
    poolsViewTab,
    isLoading,
    poolsByTokenSymbol,
  };
});
