import {
  METACRM_API_KEY,
  SCRIPT_INTEGRITY,
  WIDGET_SCRIPT_LINK,
} from 'meta-crm';

async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById('widget-dom-id')) return;

    const script = document.createElement('script');
    script.crossOrigin = 'anonymous';
    script.id = 'widget-dom-id';
    script.src = src;
    script.integrity = SCRIPT_INTEGRITY;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    if (fjs.parentNode) {
      fjs.parentNode.insertBefore(script, fjs);
    }
  });
}

(async function () {
  try {
    await loadScript(WIDGET_SCRIPT_LINK);
    MetaCRMWidget.init({
      apiKey: METACRM_API_KEY,
    });
  } catch (error) {
    console.error('Failed to load widget.js', error);
  }
})();

export {};
