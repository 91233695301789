import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useDialogStore = defineStore('dialogStore', () => {
  const dialogTitle = ref<string>('');
  const hasBack = ref<boolean>(false);
  const onBack = ref();
  const showHeader = ref<boolean>(true);
  const closable = ref<boolean>(false);

  const setDialogTitle = (title: string) => {
    dialogTitle.value = title;
  };
  const setHasBack = (value: boolean) => {
    hasBack.value = value;
  };

  const resetDialogState = () => {
    closable.value = false;
    dialogTitle.value = '';
    hasBack.value = false;
    showHeader.value = true;
  };

  const setShowHeader = (value: boolean) => (showHeader.value = value);

  const setClosable = (value: boolean) => (closable.value = value);

  const constructedBack = (cb: any): any => (onBack.value = cb);

  return {
    dialogTitle,
    hasBack,
    setDialogTitle,
    setHasBack,
    resetDialogState,
    onBack,
    constructedBack,
    showHeader,
    setShowHeader,
    closable,
    setClosable,
  };
});
