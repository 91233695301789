import {
  L2_CHAIN_ID,
  MODULES_V05_ACCOUNT,
  MODULES_V1_ACCOUNT,
  REST_URL,
  STAKING_ACCOUNT,
} from './index';
import { BTC, USDT } from '@/constants/tokens';
import {
  APTOS_TESTNET_CHAIN_ID,
  COIN_INFO,
  COIN_STORE,
  COINS_ACCOUNT,
  MAINNET_CHAIN_ID,
  MODULES_ACCOUNT,
} from '@/constants';
import { SemverVersion } from '@/types';
import { ChainsEnum, CORRECT_CHAIN } from './chains';

export type Network = {
  id: number;
  title: string;
  shortTitle: string;
  restUrl: string;
  defaultToken: string;
  modules: {
    Faucet: string;
    Scripts: string;
    LiquidityPool: string;
    CoinInfo: string;
    CoinStore: string;
  };
  // tokens: IPersistedToken[];
};

export type TPresetSource =
  | 'preset'
  | 'link'
  | 'file'
  | 'partner'
  | 'configurator';

export type Pool = {
  chainId: number;
  address: string;
  module: string;
  curve: string;
  tokens: [string, string];
};

export interface IPool {
  // struct LiquidityPool < phantom X, phantom Y, phantom Curve > has key {
  coin_x_reserve: number;
  coin_y_reserve: number;
  last_block_timestamp: number;
  last_price_x_cumulative: number;
  last_price_y_cumulative: number;
  // lp_mint_cap: coin:: MintCapability<LP<X, Y, Curve>>,
  // lp_burn_cap: coin:: BurnCapability<LP<X, Y, Curve>>,
  x_scale: number;
  y_scale: number;
  locked: boolean;
}

export type TNetworkPreset = {
  name: string;
  source: TPresetSource;
  version: SemverVersion;
  image?: string;
};

export const MAIN_CHAIN_ID = 97;

const CORRECT_CHAIN_TYPE = CORRECT_CHAIN.id;

export const CORRECT_CHAIN_ID =
  CORRECT_CHAIN_TYPE === ChainsEnum['aptos-mainnet']
    ? MAINNET_CHAIN_ID
    : ChainsEnum['aptos-testnet']
    ? APTOS_TESTNET_CHAIN_ID
    : ChainsEnum['lumio-testnet']
    ? L2_CHAIN_ID
    : 0;
export enum SWITCH_NETWORK_ID {
  'mainnet' = 1,
  'testnet' = 2,
  'movement' = 27,
  'l2' = 100,
}

export const CHAIN_ID_TO_SWITCH_NETWORK_ID: Record<
  ChainsEnum,
  SWITCH_NETWORK_ID
> = {
  [ChainsEnum['aptos-mainnet']]: SWITCH_NETWORK_ID.mainnet,
  [ChainsEnum['aptos-testnet']]: SWITCH_NETWORK_ID.testnet,
  [ChainsEnum['lumio-testnet']]: SWITCH_NETWORK_ID.l2,
  [ChainsEnum['movement-testnet']]: SWITCH_NETWORK_ID.movement,
};

export const CURVE_UNSTABLE = `${MODULES_ACCOUNT}::curves::Uncorrelated`;
export const CURVE_UNSTABLE_V05 = `${MODULES_V05_ACCOUNT}::curves::Uncorrelated`;
export const CURVE_STABLE = `${MODULES_ACCOUNT}::curves::Stable`;
export const CURVE_STABLE_V05 = `${MODULES_V05_ACCOUNT}::curves::Stable`;

export type TCurveShortNames = 'stable' | 'unstable';
export const CURVE_SHORT_NAMES: TCurveShortNames[] = ['stable', 'unstable'];

export type TDefaultToken = 'BTC' | 'USDT';

export const APTOS_DEFAULT_TOKENS_MAPPING: Record<TDefaultToken, string> = {
  BTC: `${BTC}`,
  USDT: `${USDT}`,
};

export const NETWORKS_MODULES = {
  Staking: `${STAKING_ACCOUNT}::scripts`,
  Scripts: `${MODULES_ACCOUNT}::scripts_v2`,
  Faucet: `${COINS_ACCOUNT}::faucet`,
  FaucetL2: `0x3965ec210828225ca1175b88343007e43a18bd18b5348ff3fc8be8a7c066d6c7::faucet`,
  LiquidityPool: `${MODULES_ACCOUNT}::liquidity_pool`,
  CoinInfo: `${COIN_INFO}`,
  CoinStore: `${COIN_STORE}`,
  LiquidityV1Pool: `${MODULES_V1_ACCOUNT}::pool`,
  LiquidityV1BinHelper: `${MODULES_V1_ACCOUNT}::bin_helper`,
};

export const NETWORKS: Network[] = [
  {
    id: MAINNET_CHAIN_ID,
    title: 'Aptos Mainnet',
    shortTitle: 'Aptos',
    restUrl: 'https://api.mainnet.aptoslabs.com/v1',
    defaultToken: '0x1::aptos_coin::AptosCoin',
    modules: NETWORKS_MODULES,
  },
  {
    id: APTOS_TESTNET_CHAIN_ID,
    title: 'Aptos Testnet',
    shortTitle: 'Aptos',
    restUrl: 'https://api.testnet.aptoslabs.com/v1',
    defaultToken: '0x1::aptos_coin::AptosCoin',
    modules: NETWORKS_MODULES,
  },
  {
    id: L2_CHAIN_ID,
    title: 'L2',
    shortTitle: 'Aptos',
    restUrl: REST_URL,
    defaultToken: '0x1::native_coin::NativeCoin',
    modules: NETWORKS_MODULES,
  },
];
