import { PontemWallet } from '@pontem/wallet-adapter-plugin';
import { BitgetWallet } from '@bitget-wallet/aptos-wallet-adapter';
import { NightlyWallet } from '@nightlylabs/aptos-wallet-adapter-plugin';
import { FewchaWallet } from 'fewcha-plugin-wallet-adapter';
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';
import { MSafeWalletAdapter } from '@msafe/aptos-wallet-adapter';
import { TrustWallet } from '@trustwallet/aptos-wallet-adapter';
import { OKXWallet } from '@okwallet/aptos-wallet-adapter';
import { useWallet } from '@aptos-labs/wallet-adapter-vue';

const supportedWallets = [
  new PontemWallet(),
  new BitgetWallet(),
  new NightlyWallet(),
  new FewchaWallet(),
  new MartianWallet(),
  new MSafeWalletAdapter(),
  new TrustWallet(),
  new OKXWallet(),
];

const {
  connected,
  isLoading,
  account,
  network,
  wallet,
  wallets,
  autoConnect,
  connect,
  disconnect,
  signAndSubmitTransaction,
  signTransaction,
  submitTransaction,
  signMessage,
  signMessageAndVerify,
  changeNetwork,
} = useWallet({
  plugins: supportedWallets,
  onError: (error) => {
    console.log(error);
  },
});

export function useWalletPlugin(_autoConnect = true) {
  autoConnect.value = _autoConnect;
  return {
    connected,
    isLoading,
    account,
    network,
    wallet,
    wallets,
    autoConnect,
    connect,
    disconnect,
    signAndSubmitTransaction,
    signTransaction,
    submitTransaction,
    signMessage,
    signMessageAndVerify,
    changeNetwork,
  };
}

export * from '@aptos-labs/wallet-adapter-vue';
