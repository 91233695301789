import { App } from 'vue';
import RestModule from 'ls-api';

export interface HttpPluginOptions {
  baseURL: string;
  sentry: {
    isSentryEnabled: boolean;
    captureException(exception: unknown, captureContext?: unknown): void;
  };
}

export default {
  install(app: App, options: HttpPluginOptions) {
    if (!options || !options.baseURL) {
      throw new Error('HttpPlugin requires a baseURL option');
    }

    const api = new RestModule({
      baseURL: options.baseURL,
      sentry: {
        isSentryEnabled: options.sentry.isSentryEnabled,
        captureException: options.sentry.captureException,
      },
    });

    app.provide('$http', api.controller);
    app.config.globalProperties.$http = api.controller;
  },
};
