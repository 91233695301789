/**
 * Logic to work with contract
 */
import { d } from '@/utils/index';
import {
  CURVE_STABLE_V05,
  CURVE_UNSTABLE_V05,
  CURVE_STABLE,
  CURVE_UNSTABLE,
} from '@/constants/networks';
import { Decimal } from 'decimal.js';
import { Buffer } from 'buffer';
import {
  VERSION_0,
  VERSION_0_5,
  MODULES_ACCOUNT,
  MODULES_V05_ACCOUNT,
  RESOURCES_ACCOUNT,
  RESOURCES_V05_ACCOUNT,
  SCRIPTS_V1,
  SCRIPTS_V2,
  VERSION_1,
  RESOURCES_V1_ACCOUNT,
  MODULES_V1_ACCOUNT,
} from '@/constants';
import { TCurveType } from '@/types';

const EQUAL = 0;
const LESS_THAN = 1;
const GREATER_THAN = 2;

export function composeType(address: string, generics: string[]): string;
export function composeType(
  address: string,
  struct: string,
  generics?: string[],
): string;
export function composeType(
  address: string,
  module: string,
  struct: string,
  generics?: string[],
): string;
export function composeType(address: string, ...args: unknown[]): string {
  const generics: string[] = Array.isArray(args[args.length - 1])
    ? (args.pop() as string[])
    : [];
  const chains = [address, ...args].filter(Boolean);

  let result: string = chains.join('::');

  if (generics && generics?.length) {
    result += `<${generics.join(',')}>`;
  }

  return result;
}

export function extractAddressFromType(type: string) {
  return type.split('::')[0];
}

export function extractTokenSymbolFromType(type: string) {
  const typeArr = type.split('::');
  return typeArr[typeArr.length - 1];
}

function cmp(a: number, b: number) {
  if (a === b) {
    return EQUAL;
  } else if (a < b) {
    return LESS_THAN;
  } else {
    return GREATER_THAN;
  }
}

function compare(symbolX: string, symbolY: string) {
  const lenCmp = cmp(symbolX.length, symbolY.length);
  if (lenCmp != EQUAL) {
    return lenCmp;
  }
  let i = 0;
  while (i < symbolX.length && i < symbolY.length) {
    const elem_cmp = cmp(symbolX.charCodeAt(i), symbolY.charCodeAt(i));
    if (elem_cmp != EQUAL) return elem_cmp;
    i++;
  }
  return EQUAL;
}

/**
 * Removes all of leading zeros from address
 * @param {String} address
 * @returns {String} Normalized address
 */
export function normalizeAddress(address: string) {
  if (address.startsWith('0x')) {
    address = address.substring(2);
  }

  if (address.length != 64) {
    while (address.length < 64) {
      address = `0${address}`;
    }
  }

  return address;
}

function cmp_addresses(a: string, b: string) {
  a = normalizeAddress(a);
  b = normalizeAddress(b);

  const a_buf = Buffer.from(a, 'hex');
  const b_buf = Buffer.from(b, 'hex');

  for (let i = 0; i < 32; i++) {
    if (a_buf[i] < b_buf[i]) {
      return LESS_THAN;
    } else if (a_buf[i] > b_buf[i]) {
      return GREATER_THAN;
    }
  }

  return EQUAL;
}

function compare_types(coin_x: string, coin_y: string) {
  const coin_x_parts = coin_x.split('::').reverse();
  const coin_y_parts = coin_y.split('::').reverse();

  const coin_x_address = coin_x_parts.pop() as string;
  const coin_y_address = coin_y_parts.pop() as string;

  for (let i = 0; i < 2; i++) {
    const c = compare(coin_x_parts[i], coin_y_parts[i]);
    if (c != EQUAL) {
      return c;
    }
  }

  return cmp_addresses(coin_x_address, coin_y_address);
}

/**
 * Compare sorting between two coin types
 *
 * @param coin_x string with full address of coin
 * @param coin_y string with full address of coin
 * @returns boolean
 */
export function is_sorted(coin_x: string, coin_y: string) {
  return compare_types(coin_x, coin_y) == LESS_THAN;
}

/**
 * Swap math helpers
 */
export function withSlippage(slippage: number, value: number) {
  const multiply = 10000;
  const slippagePercent = slippage * multiply;

  return d(value).minus(d(value).mul(slippagePercent).div(multiply)).toNumber();
}

/**
 * Calculate return of Liquidity Coins
 */
const MINIMAL_LIQUIDITY = 10000;

export function calcReceivedLP({
  x,
  y,
  xReserve,
  yReserve,
  lpSupply,
}: {
  x: number;
  y: number;
  xReserve: number;
  yReserve: number;
  lpSupply?: number;
}): number {
  const dxReserve = d(xReserve);
  const dyReserve = d(yReserve);
  const dx = d(x);
  const dy = d(y);
  const dSupply = d(lpSupply);

  if (dxReserve.eq(0) || dyReserve.eq(0)) {
    return Decimal.sqrt(dx.mul(dy)).minus(MINIMAL_LIQUIDITY).toNumber();
  }

  const xLp = dx.mul(dSupply).div(dxReserve);
  const yLp = dy.mul(dSupply).div(dyReserve);

  return Decimal.min(xLp, yLp).toNumber();
}

export function getOptimalLiquidityAmount(
  xDesired: number,
  xReserve: number,
  yReserve: number,
): number {
  return Number(d(xDesired).mul(d(yReserve)).div(d(xReserve)).toFixed(0));
}

/**
 * Calculate output amount after burned
 */
export function calcOutputBurnLiquidity({
  xReserve,
  yReserve,
  lpSupply,
  toBurn,
}: {
  xReserve: number;
  yReserve: number;
  lpSupply: number;
  toBurn: number;
}) {
  const xReturn = d(toBurn).mul(xReserve).div(lpSupply);
  const yReturn = d(toBurn).mul(yReserve).div(lpSupply);

  if (xReturn.eq(0) || yReturn.eq(0)) {
    return undefined;
  }

  return {
    x: xReturn.truncated().toNumber(),
    y: yReturn.truncated().toNumber(),
  };
}

/**
 * Calculate string of LP Token with sorted args
 *
 * @param pool - pool wrap with tokens array and curve string
 * @returns string
 */
export function lpTokenNameStr(
  pool: {
    tokens: [string?, string?];
    curve: string;
  },
  contract?: number,
) {
  const { tokens, curve } = pool;
  if (
    !Array.isArray(tokens) ||
    tokens[0] === undefined ||
    tokens[1] === undefined
  )
    return '';
  const [fromToken, toToken] = is_sorted(tokens[0], tokens[1])
    ? [tokens[0], tokens[1]]
    : [tokens[1], tokens[0]];
  const resourceAccount = getResourcesAccount(contract);
  return composeType(resourceAccount, 'lp_coin', 'LP', [
    fromToken,
    toToken,
    curve,
  ]);
}

/**
 * Get Resources Account Address for a Contract Version
 *
 * @throws Unknown contract version requested
 *
 * @param contract version number
 * @returns string with resources account address
 */
export function getResourcesAccount(contract?: number): string {
  if (contract === undefined || contract === VERSION_0) {
    return RESOURCES_ACCOUNT;
  }

  if (contract === VERSION_0_5) return RESOURCES_V05_ACCOUNT;

  if (contract === VERSION_1) return RESOURCES_V1_ACCOUNT;

  throw new Error(
    `getResourcesAccount: Unknown contract version requested: ${contract}`,
  );
}

/**
 * Get Modules Account Address for a Contract Version
 *
 * @throws Unknown contract version requested
 *
 * @param contract version number
 * @returns string with modules account address
 */
export function getModulesAccount(contract?: number): string {
  if (contract === undefined || contract === VERSION_0) {
    return MODULES_ACCOUNT;
  }

  if (contract === VERSION_0_5) return MODULES_V05_ACCOUNT;

  if (contract === VERSION_1) return MODULES_V1_ACCOUNT;

  throw new Error(
    `getModulesAccount: Unknown contract version requested: ${contract}`,
  );
}

/**
 * Get contract version number based on passed curve type
 *
 * @throws Unknown curve passed
 *
 * @param curve full type of curve
 * @returns version
 */
export function getContractVersionFromCurve(curve: string): number {
  if ([CURVE_STABLE_V05, CURVE_UNSTABLE_V05].includes(curve)) {
    return VERSION_0_5;
  }
  if ([CURVE_STABLE, CURVE_UNSTABLE].includes(curve)) return VERSION_0;
  throw new Error(`Unknown curve passed: ${curve}`);
}

/**
 * Get Script Modules Name for a Contract Version
 *
 * @throws Unknown contract version requested
 *
 * @param contract version number
 * @returns script with scripts module name value
 */
export function getScriptsFor(version: number): string {
  switch (version) {
    case VERSION_0:
      return SCRIPTS_V2;
    case VERSION_0_5:
      return SCRIPTS_V1;
  }
  throw new Error(
    `getScriptsFor: Unknown contract version requested: ${version}`,
  );
}

/**
 * Compute full curve type for given contract version
 *
 * @param type short name of curve
 * @param contract version
 * @returns curve full type
 */
export function getCurve(type: TCurveType, contract?: number): string {
  if (contract === VERSION_0_5) {
    if (type === 'stable') {
      return CURVE_STABLE_V05;
    }
    return CURVE_UNSTABLE_V05;
  }
  if (type === 'unstable') {
    return CURVE_UNSTABLE;
  }
  return CURVE_STABLE;
}

/**
 * Compute short curve name for given curve full type
 *
 * @param type full type of curve
 * @returns short curve name
 */
export function getShortCurveFromFull(
  type: string | undefined,
): TCurveType | undefined {
  if (type === undefined) return;
  if (type === CURVE_STABLE || type === CURVE_STABLE_V05) return 'stable';
  if (type === CURVE_UNSTABLE || type === CURVE_UNSTABLE_V05) return 'unstable';
  throw new Error('Wrong curve type passed: ' + type);
}

/**
 * checks whether the curve name is complex
 * @param curve
 * @returns boolean
 */
export function isComplexCurve(curve: string) {
  return curve.split('::').length === 3;
}

export function getBinStepFromType(binStepType: string): number {
  const anchor = 'bin_steps::X';
  const firstIndex = binStepType.indexOf(anchor);
  const binStep = binStepType.slice(firstIndex + anchor.length);
  return Number(binStep);
}
