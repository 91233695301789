import axios, {
  AxiosInstance,
  AxiosInterceptorOptions,
  AxiosResponse,
  CreateAxiosDefaults,
} from "axios";
import HttpController from "../controllers/httpController";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

interface Sentry {
  isSentryEnabled: boolean;

  captureException(exception: unknown, captureContext?: unknown): void;
}

const DEFAULT_TIMEOUT = 5000;

const DEFAULT_OPTIONS: CreateAxiosDefaults = {
  headers: DEFAULT_HEADERS,
  timeout: DEFAULT_TIMEOUT,
};

export default class RestModule {
  readonly axiosInstance: AxiosInstance;
  readonly sentry: Sentry;
  readonly controller: HttpController;

  constructor({
    baseURL,
    sentry,
    options = DEFAULT_OPTIONS,
  }: {
    baseURL: string;
    sentry: Sentry;
    options?: CreateAxiosDefaults;
  }) {
    if (!baseURL) throw new Error("baseURL is required");
    this.axiosInstance = axios.create({
      baseURL,
      ...DEFAULT_OPTIONS,
      ...options,
    });
    this.sentry = sentry;
    this.controller = new HttpController(this.axiosInstance);
    this.setupInterceptors();
  }

  /**
   * Add a response interceptor to the API client
   * @param onFulfilled - Function to be called when the request is successful
   * @param onRejected - Function to be called when the request encounters an error
   * @param options - Options for the interceptor
   */
  public addResponseInterceptor<T = any, D = any>(
    onFulfilled: (response: AxiosResponse<T, D>) => any | Promise<any>,
    onRejected?: (error: any) => any,
    options?: AxiosInterceptorOptions
  ): void {
    this.axiosInstance.interceptors.response.use(
      onFulfilled,
      onRejected,
      options
    );
  }

  private setupInterceptors() {
    if (this.sentry && this.sentry.isSentryEnabled) {
      this.addResponseInterceptor(
        (response) => response,
        (error) => {
          this.sentry.captureException(error);
          return Promise.reject(error);
        }
      );
    } else {
      console.warn("Sentry is not enabled");
    }
  }
}
