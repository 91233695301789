import { getStoredTokenUsdEquivalent } from '@/composables/useInputServices';
import { CORRECT_CHAIN_ID } from '@/constants/networks';
import {
  STAKING_ACCOUNT,
  LP_DECIMALS,
  WEEK_SEC,
  CORRECT_CHAIN,
  ChainsEnum,
} from '@/constants';
import { IPersistedTokenExtended } from './useTokensStore';
import { useTokensStore } from '@/store';
import { getPoolLpStr, getPoolStr, getStakingPoolStr } from '@/utils/pools';
import {
  IPersistedStakingPool,
  IStakingPool,
  TStakingDeposit,
  TStakingDepositKeys,
} from '@/types/pools';
import { computed, reactive, ref, watch } from 'vue';
import { usePoolsStore } from './usePoolsStore';
import { defineStore } from 'pinia';
import isNumber from 'lodash/isNumber';
import { useAptosClient } from '@/composables/useAptosClient';
import { useStore } from './useStore';
import { useNFTStore } from './useNFTStore';
import { decimalsMultiplier } from '@/utils';
import {
  calcOutputBurnLiquidity,
  getContractVersionFromCurve,
} from '@/utils/contracts';
import CoinsRegistry from '@pontem/coins-registry';

const ChainToNetwork = {
  [ChainsEnum['aptos-mainnet']]: 'mainnet',
  [ChainsEnum['aptos-testnet']]: 'testnet',
  [ChainsEnum['lumio-testnet']]: 'l2',
  [ChainsEnum['movement-testnet']]: '',
};

export const useStakingStore = defineStore('stakingStore', () => {
  const store = useStore();
  const poolsStore = usePoolsStore();
  const aptos = useAptosClient();
  const nftStore = useNFTStore();

  const poolsMap = reactive<Record<string, IPersistedStakingPool>>({});
  const poolsWithDeposits = ref<string[]>([]);

  const loadPool = async (type: string, farmPool: IPersistedStakingPool) => {
    // load staking pool data
    const response = await aptos.client.getAccountResource(
      farmPool.address,
      type,
    );

    if (!response) return;

    farmPool.endTimestamp = +response.data.end_timestamp;
    farmPool.startTimestamp = +response.data.start_timestamp;
    farmPool.lastUpdated = +response.data.last_updated;
    farmPool.claimable = +response.data.accum_reward;
    farmPool.totalBoosted = +response.data.total_boosted;
    farmPool.stakeScale = +response.data.scale;
    farmPool.stakeCoins = +response.data.stake_coins.value;
    farmPool.accumReward = +response.data.accum_reward;
    farmPool.rewardPerSecond = +response.data.reward_per_sec;
    farmPool.handle = response.data.stakes.handle;

    const ver = getContractVersionFromCurve(farmPool.pool.curve);

    const poolStr = getPoolStr(
      farmPool.pool.coinX,
      farmPool.pool.coinY,
      farmPool.pool.curve,
      ver,
    );
    const decimalsReward = decimalsMultiplier(
      farmPool.rewardCoin.decimals,
    ).toNumber();

    /**
     * See doc:
     * https://www.notion.so/walletsofwings/Staking-d89ea2c183ea494cbdf2bfb890f04458#9c15e165375f4bf1a260e43ebd6d40a8
     */
    const decimalsLP = decimalsMultiplier(LP_DECIMALS).toNumber();
    const numerator = farmPool.rewardPerSecond * WEEK_SEC;
    const denominator =
      farmPool.stakeCoins + farmPool.totalBoosted + decimalsLP;
    const estimation = numerator / denominator;
    const normalizer = decimalsLP / decimalsReward;
    farmPool.rewardPerWeekOnLp = estimation * normalizer * decimalsReward;

    Promise.all([
      poolsStore.loadPoolByType(poolStr),
      poolsStore.fetchTotalLp(poolStr),
    ]).then(async ([lpPool]) => {
      /**
       * Calculate TVL
       */
      if (!lpPool?.lpTotal || !farmPool?.stakeCoins) {
        return;
      }
      const afterBurn = calcOutputBurnLiquidity({
        xReserve: lpPool.reserveX,
        yReserve: lpPool.reserveY,
        lpSupply: lpPool.lpTotal,
        toBurn: farmPool.stakeCoins,
      });
      if (!afterBurn?.x || !afterBurn?.y) {
        console.error('Wrong amount after burn');
        return;
      }
      // calculate X in USD
      const xRate = await getStoredTokenUsdEquivalent({
        token: lpPool.coinX,
        amount: afterBurn?.x,
      });
      // calculate Y in USD
      const yRate = await getStoredTokenUsdEquivalent({
        token: lpPool.coinY,
        amount: afterBurn?.y,
      });
      if (xRate === undefined || yRate === undefined) {
        console.error('Wrong USD equivalent');
        return;
      }
      farmPool.tvl = xRate + yRate;

      /**
       * Calculate APR
       */
      setTimeout(async () => {
        // reward per week in USD
        farmPool.rewardPerWeekOnLp;
        const rewardUsd = await getStoredTokenUsdEquivalent({
          token: farmPool.reward,
          amount: farmPool.rewardPerWeekOnLp,
        });
        // 1 LP in USD
        const oneLP = decimalsMultiplier(LP_DECIMALS).toNumber();
        const afterBurnOneLp = calcOutputBurnLiquidity({
          xReserve: lpPool.reserveX,
          yReserve: lpPool.reserveY,
          lpSupply: lpPool.lpTotal as number,
          toBurn: oneLP,
        });
        if (!afterBurnOneLp?.x || !afterBurnOneLp?.y) {
          console.error('Wrong amount after burn');
          return;
        }
        // calculate X in USD
        const oneLpXRate = await getStoredTokenUsdEquivalent({
          token: lpPool.coinX,
          amount: afterBurnOneLp?.x,
        });
        // calculate Y in USD
        const oneLpYRate = await getStoredTokenUsdEquivalent({
          token: lpPool.coinY,
          amount: afterBurnOneLp?.y,
        });
        if (
          rewardUsd === undefined ||
          xRate === undefined ||
          yRate === undefined ||
          oneLpYRate === undefined ||
          oneLpXRate === undefined
        ) {
          console.error('Wrong USD equivalent');
          return;
        }
        const oneLpInUSD = oneLpXRate + oneLpYRate;
        farmPool.apr = ((rewardUsd / oneLpInUSD) * 100 * 365) / 7;
      });
    });

    if (response.data.nft_boost_config.vec.length > 0) {
      farmPool.nft = {
        boostPercent: +response.data.nft_boost_config.vec[0].boost_percent,
        collectionName: response.data.nft_boost_config.vec[0].collection_name,
        collectionOwner: response.data.nft_boost_config.vec[0].collection_owner,
      };
    }
    setTimeout(() => fetchPoolDeposits(type, farmPool), 500);
  };

  function constructStakingPoolType(
    stakingInfo: IStakingPool,
    contract?: number,
  ): string {
    const { coinX, coinY, curve } = stakingInfo.pool;
    const lp = getPoolLpStr(coinX, coinY, curve, contract);
    return getStakingPoolStr(lp, stakingInfo.reward);
  }
  const savePool = false;

  async function registerStakingPool(
    stakingInfo: IStakingPool,
    { rewrite = true, lazy = true },
  ): Promise<IPersistedStakingPool | undefined> {
    const { coinX, coinY, curve } = stakingInfo.pool;
    const ver = getContractVersionFromCurve(curve);

    const lp = getPoolLpStr(coinX, coinY, curve, ver);
    const stakingPool = constructStakingPoolType(stakingInfo, ver);
    const poolKey = `${stakingInfo.address}-${stakingPool}`;
    // Check try to register a duplicate
    if (!rewrite && poolsMap[poolKey]) {
      if (!poolsMap[poolKey].order && isNumber(stakingInfo?.order)) {
        poolsMap[poolKey].order = stakingInfo.order;
      }
      return poolsMap[poolKey];
    }

    const tokensStore = useTokensStore();
    const [_rewardCoin, poolItem] = await Promise.all([
      tokensStore.searchToken(stakingInfo.reward),
      poolsStore.getPool(coinX, coinY, curve, ver, savePool),
    ]);

    if (!poolItem) {
      return undefined;
    }

    const rewardCoin = tokensStore.getToken(
      stakingInfo.reward,
    ) as IPersistedTokenExtended;
    const persistedPool: IPersistedStakingPool = reactive({
      address: stakingInfo.address,
      reward: stakingInfo.reward,
      lp,
      pool: stakingInfo.pool,
      nftInfo: stakingInfo.nftInfo,
      order: stakingInfo.order,
      claimable: 0,
      deposit: false,
      redemption: undefined,
      left: '',
      rewardPerSecond: 0,
      rewardCoin,
      poolItem,
    });

    poolsMap[poolKey] = persistedPool;

    if (lazy) {
      loadPool(stakingPool, persistedPool);
    } else {
      await loadPool(stakingPool, persistedPool);
    }

    return persistedPool;
  }

  function registerPools(list: IStakingPool[]): void {
    if (!list || !Array.isArray(list)) return;
    const registerPoolOptions = { rewrite: true, isDefault: true };

    list.forEach((item) => {
      if (item.pool.networkId !== CORRECT_CHAIN_ID) return;
      registerStakingPool(item, registerPoolOptions);
    });
  }

  async function fetchPoolsList() {
    await nftStore.getNFTHistory();
    const staking = CoinsRegistry.getStakingPoolsFor(
      ChainToNetwork[CORRECT_CHAIN.id],
    ) as IStakingPool[];

    return registerPools(staking);
  }

  /*
  Loop through resources and get from staking pools stake.handle addresses
  for table handles.
  Then - send post request: https://fullnode.testnet.aptoslabs.com/v1/spec#/operations/get_table_item
  example:
    table_handle:
      0x6c6e95ec24d07bf3b36715af4fc67b620d861b284a987fd4e01c66717042bf7a
    Payload:
      {§
        "key_type": "address",
        "value_type": "0x2ec4190dd6eec80913e02da22de89700a9b5e13e27b51750191b7ceb3eee1a2f::stake::UserStake",
        "key":"0xc5b434a8af482e8f593d22029f872f9ca8f2d9f8e3566afbf40ba50a55be8a99"
      }
  */
  /**
   * Fetch row from staking table with user's deposit data
   *
   * @param type - address of pool
   * @param pool - persisted pool
   * @returns Promise<void>
   */
  async function fetchPoolDeposits(type: string, pool: IPersistedStakingPool) {
    if (!pool.handle) return;
    if (!store.account.value?.address) return;
    const { address } = store.account.value;
    const response = await aptos.client.getTableRow(pool.handle, {
      key_type: 'address',
      value_type: `${STAKING_ACCOUNT}::stake::UserStake`,
      key: address,
    });

    if (!response) return;
    const poolKey = `${pool.address}-${type}`;

    if (!poolsWithDeposits.value.includes(poolKey)) {
      poolsWithDeposits.value.push(poolKey);
    }
    if (poolsMap[poolKey].deposit !== false) {
      const keys: TStakingDepositKeys[] = [
        'amount',
        'boosted_amount',
        'earned_reward',
        'unlock_time',
        'unobtainable_reward',
        'nft',
      ];
      keys.forEach((key: TStakingDepositKeys) => {
        (poolsMap[poolKey].deposit as TStakingDeposit)[key] = response[key];
      });
    } else {
      poolsMap[poolKey].deposit = reactive(response);
    }
  }

  /**
   * Clean list of deposits and false all deposit fields in staking pools
   */
  function clearDeposits() {
    poolsWithDeposits.value.forEach((key) => {
      poolsMap[key].deposit = false;
    });
    poolsWithDeposits.value = [];
  }

  /**
   * Clean deposits and request each staking pool deposit from remote
   */
  async function updateDeposits() {
    clearDeposits();
    return Promise.all(
      Object.keys(poolsMap).map((key) => {
        const pool = poolsMap[key];
        const ver = getContractVersionFromCurve(pool?.pool?.curve);
        const type = constructStakingPoolType(pool, ver);
        return fetchPoolDeposits(type, pool);
      }),
    );
  }

  watch(
    () => store.account.value?.address,
    async () => {
      await updateDeposits();
    },
  );

  const deposits = computed(() => {
    return poolsWithDeposits.value.map((key) => poolsMap[key]);
  });

  const currentDeposits = computed(() => {
    return deposits.value.filter((deposit) => {
      return (
        deposit.deposit &&
        deposit.deposit.amount &&
        deposit.deposit.amount !== '0'
      );
    });
  });

  return {
    poolsMap,
    fetchPoolDeposits,
    deposits,
    currentDeposits,
    fetchPoolsList,
    updateDeposits,
  };
});
