import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { APTOS_INDEXER_API_KEY, APTOS_INDEXER_API_URL } from '@/constants';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';

// HTTP connection to the API
const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: APTOS_INDEXER_API_URL + '/v1/graphql',
});

// Cache implementation
const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'API-KEY': APTOS_INDEXER_API_KEY,
    },
  });
  return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
});

export default apolloClient;
