export interface PoolCoin {
  type: string; // '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT'
  chainId: number;
  decimals: number;
  logoUrl: string;
  name: string;
  source: string;
  symbol: string;
  symbolWithBridge: string;
  reserve: string; // '1442786121500'
  reserveUsd: string; // '1442945.712815217778'
  priceUsd: number; // 1.0001106132869173
}

export interface Pool {
  type: string; // '0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::liquidity_pool::LiquidityPool<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT,0x1::aptos_coin::AptosCoin,0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated>'
  version: 0 | 0.5;
  curve: "stable" | "unstable";
  tvl: string; // '3238480.9261110977523'
  coinX: PoolCoin;
  coinY: PoolCoin;
  networkId: number;
  fee: string; // '30'
  lastBlockTimestamp: string; // '1713173856'
  exceptional?: boolean;
}

export type TCoinSource =
  | "pontem"
  | "partners"
  | "import"
  | "pool"
  // partners sources
  | "aptoge"
  | "argo"
  | "amnis"
  | "celer"
  | "chainx"
  | "ditto"
  | "layerzero"
  | "mojito"
  | "tortuga"
  | "wormhole";

export const MAINNET_CHAIN_ID = 1;
export const APTOS_TESTNET_CHAIN_ID = 2;
export const PONTEM_TESTNET_CHAIN_ID = 4;
export const L2_CHAIN_ID = 5;

export type ChainId =
  | typeof MAINNET_CHAIN_ID
  | typeof APTOS_TESTNET_CHAIN_ID
  | typeof PONTEM_TESTNET_CHAIN_ID
  | typeof L2_CHAIN_ID;

export namespace GetPools {
  interface Pool {
    version: number;
    curve: "stable" | "unstable";
    coinX: {
      chainId: number;
      decimals: number;
      logo_url: string;
      name: string;
      order: number;
      source: string; // 'layerzero'
      symbol: string;
      type: string; // '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT'
      symbol_with_bridge: string; // 'zUSDT'
    };
    coinY: {
      caution: boolean;
      chainId: number;
      decimals: number;
      logo_url: string;
      name: string;
      order: number;
      source: string; // 'aptos'
      symbol: string;
      type: string; // '0x1::aptos_coin::AptosCoin'
      symbol_with_bridge: string; // 'APT'
    };
    coinXSymbol: string; // 'usdt'
    coinYSymbol: string; // 'apt'
    networkId: number;
    resourceType: string; // '0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::liquidity_pool::LiquidityPool<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT,0x1::aptos_coin::AptosCoin,0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Stable>'
    coinXPrice: number; // 1.0009692279216449
    coinYPrice: number; // 12.391013388210729
    tvl: string; // '0'
    sentioTvl: null | string;
  }

  export type Response = Pool[];
}

export namespace GetFarms {
  export type Query = {
    networkId: number;
  };

  interface Farm {
    version: number;
    order: number;
    address: string; // '0x32d42921e177db242f5550ebd5a899fd84d539511d95f5f032e8a2a8900b6354'
    lp: string; // '0x05a97986a9d031c4567e15b797be516910cfcb4156312482efc6a19c0a30c948::lp_coin::LP<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC,0x1::aptos_coin::AptosCoin,0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated>'
    stakingPoolType: string; // '0xb247ddeee87e848315caf9a33b8e4c71ac53db888cb88143d62d2370cca0ead2::stake::StakePool<0x05a97986a9d031c4567e15b797be516910cfcb4156312482efc6a19c0a30c948::lp_coin::LP<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC,0x1::aptos_coin::AptosCoin,0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated>,0x1::aptos_coin::AptosCoin>'
    pool: {
      type: string;
      version: number;
      coinX: PoolCoin; // '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC'
      coinY: PoolCoin; // '0x1::aptos_coin::AptosCoin'
      curve: string; // '0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated'
      networkId: number;
      fee: string;
      lastBlockTimestamp: string;
      tvl: string;
      apr: number;
      volume24: number;
    };
    reward: string; // '0x1::aptos_coin::AptosCoin'
    nft: {
      url: string; // 'https://www.topaz.so/collection/Pontem-Space-Pirates-c46dd298b8'
    };
  }

  export type Response = Farm[];
}

export namespace GetTotalLiquidity {
  export type Query = {
    version: 0 | 0.5;
    address: string;
    curve: string;
    y: string;
    x: string;
  };

  export type Response = {
    x: number;
    y: number;
  };
}

export namespace GetTotalLiquidityByAddress {
  interface AddedLiquidityItem {
    type: string; // '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC,0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH,0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated'
    addedX: string; // '123'
    addedY: string; // '123'
    ver: string; // '0'
  }

  export type Query = {
    address: string;
  };

  export type Response = AddedLiquidityItem[];
}

export namespace GetAddedLiquidityByAddress {
  export type Query = {
    address: string;
  };
  export type Response = number;
}

export namespace GetTvl {
  export type Response = {
    data: number; // 39468325.66938967
  };
}

export namespace GetVolumes {
  export type Query = {
    timezone?: string;
    start?: string;
    end?: string;
  };

  interface Volume {
    timestamp: string; // '1666137600'
    value: number; // 1908254.0180783921
  }

  export type Response = {
    sum: number;
    lastTimestamp: string;
    data: Volume[];
  };
}

export namespace GetTotalSwaps {
  export type Response = {
    data: string; // '9217384'
  };
}

export namespace GetTotalSwapsByAddress {
  export type Query = {
    address: string;
  };
  export type Response = number;
}

export namespace GetApr {
  interface Pair {
    apr: number; // 1324.479007808996
    alias: {
      curve: "Stable" | "Uncorrelated";
      pair: string; // 'amStApt-APT'
      ver: "v0" | "v0.5";
    };
  }

  export type Response = {
    data: Pair[];
  };
}

export namespace GetVolume24 {
  export type Query = {
    timezone?: string;
    start?: string;
    end?: string;
  };

  interface Pair {
    pair: string; // 'APT-zUSDC'
    curve: "Stable" | "Uncorrelated";
    version: "v0" | "v0.5";
    volume24H: {
      timestamp: string; // '1709510400'
      value: number; // 1382038.555202059
    };
  }

  export type Response = {
    data: Pair[];
  };
}

export namespace GetTvlByPool {
  interface TvlValue {
    timestamp: string; // '1709510400'
    value: number; // 6856255.992195824
  }

  interface PoolTvl {
    pool: string; // 'APT-zUSDC'
    curve: "Stable" | "Uncorrelated";
    version: "v0" | "v0.5";
    tvlValues: TvlValue[];
  }

  export type Response = PoolTvl[];
}

export namespace GetTradingVolumeByAddress {
  export type Query = {
    address: string;
  };
  export type Response = number;
}

export namespace GetRegisteredPools {
  export type Query = {
    networkId?: number;
  };
  export type Response = Pool[];
}

export namespace GetTopVolumeAddress {
  export type VolumeAddress = {
    timestamp: Date;
    value: number;
    account: string;
  };

  export type Query = {
    timezone: string;
    limit?: number;
  };

  export type Response = VolumeAddress[];
}

export namespace GetRegisteredCoins {
  interface Coin {
    caution: boolean;
    chainId: ChainId;
    decimals: number;
    logo_url: string;
    name: string;
    order: number;
    source: TCoinSource;
    symbol: string;
    type: string;
    symbol_with_bridge: string;
    priceUsd: number;
    unsafe?: boolean;
  }

  export type Query = {
    /**
     * @default 1
     */
    networkId?: ChainId;
  };

  export type Response = Coin[];
}

export namespace GetUniqueUsersCount {
  export type Response = {
    data: string;
  };
}

export type TCurveType = "unstable" | "stable";

export namespace GetTradeRoute {
  export type SmartRouterMode = "default" | "direct";

  export enum SmartRouterError {
    RouteNotFound = "Route not found",
    InsufficientFunds = "Insufficient funds",
  }

  export interface Pool_V0_V05 {
    id: string;
    x_name: string;
    y_name: string;
    curve: TCurveType;
    x_val: string;
    y_val: string;
    fee: string;
    last_event: string;
    version_ls: "0" | "0.5";
  }

  export interface Pool_V1 {
    id: string;
    x_name: string;
    y_name: string;
    x_val: string;
    y_val: string;
    bin_step: number;
    fee: string;
    version_ls: "1";
  }

  export type Query<T extends SmartRouterMode> = {
    from: string;
    to: string;
    input?: string | number;
    output?: string | number;
    /** @Deprecated */
    mode?: T;
    cl?: boolean;
  } & (T extends "direct"
    ? { version: "0" | "0.5" | "1"; curve: TCurveType }
    : NonNullable<unknown>);

  export interface Pool {
    pool: Pool_V0_V05 | Pool_V1;
    inputToken: string;
    inputAmount: string;
    outputToken: string;
    outputAmount: string;
    fee: string;
  }

  export type Response = {
    /** @deprecated */
    path?: Pool[];
    /** @deprecated */
    error?: SmartRouterError | "";
    /** @deprecated */
    direct?: boolean;
    defaultMode: {
      path: Pool[];
      direct: false;
      error?: SmartRouterError | "";
    };
    directMode: {
      path: Pool[];
      direct: true;
      error?: SmartRouterError | "";
    };
  };
}
